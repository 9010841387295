import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DataGridMobileFiltersGroup } from ".";

import { IDataGridColumn, TDataGridDataParams } from "../../../models/dataGrid";

import { BaseSelect } from "../../form-fields";
import { SelectItem } from "../../form-fields/base-select/base-select";

interface Props {
  column: IDataGridColumn;
  queryParams: TDataGridDataParams;
  onUpdateSortAndFilters: (params: TDataGridDataParams) => void;
}

export const DataGridMobileFiltersColumn: FC<Props> = ({
  column,
  queryParams,
  onUpdateSortAndFilters,
}): ReactElement | null => {
  const [activeGroupKey, setActiveGroupKey] = useState<string>(
    column?.controlPanel?.activeGroupKey || "",
  );

  const groups = useMemo(
    () => column?.controlPanel?.groups || [],
    [column.controlPanel?.groups],
  );

  const activeGroup = useMemo(() => {
    return groups.find((group) => group.key === activeGroupKey);
  }, [groups, activeGroupKey]);

  const groupListForSelect: SelectItem[] = useMemo(() => {
    return groups.map(
      ({ title, key }): SelectItem => ({ label: title || key, value: key }),
    );
  }, [groups]);

  const apply = useCallback(
    (params: TDataGridDataParams) => {
      groups.forEach((group) => {
        if (group.key !== activeGroup?.key) {
          group.filters?.forEach((filter) => {
            delete params[filter.key];
          });
        }
      });

      onUpdateSortAndFilters(params);
    },
    [groups, activeGroup?.key, onUpdateSortAndFilters],
  );

  useEffect(() => {
    groups.forEach((group) => {
      group.filters?.forEach((filter) => {
        if (queryParams[filter.key]) {
          setActiveGroupKey(group.key);
        }
      });
    });
  }, [queryParams, groups]);

  if (!column.controlPanel) {
    return null;
  }

  return (
    <div className="data-grid__mobile-filters-item">
      <div className="data-grid__mobile-filters-item-title">
        {column.filterTitle || column.title}
      </div>
      {column.controlPanel.groups.length > 1 && (
        <div className="data-grid__mobile-filters-item-form-control">
          <BaseSelect
            size="medium"
            className="data-grid__mobile-filters-select-group"
            forDarkBg
            placeholder="Не выбрана"
            activeItem={activeGroupKey}
            name={activeGroupKey}
            items={groupListForSelect}
            onChange={({ value }) => {
              setActiveGroupKey(value);
            }}
          />
        </div>
      )}

      {activeGroup && (
        <DataGridMobileFiltersGroup
          group={activeGroup}
          onUpdateSortAndFilters={apply}
          queryParams={queryParams}
        />
      )}
    </div>
  );
};
