import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { SearchInput } from "..";
import { IBaseInputChange } from "../../models/base-input";
import { IDataGridColumn, IDataGridRow } from "../../models/dataGrid";
import "./popup-grid-group.scss";
import { SoloResultsGrid } from "./solo-results-grid/solo-results-grid";
import {
  ITeamGridItem,
  TeamResultsGrid,
} from "./team-results-grid/team-results-grid";
import CorpResultsGrid, {
  ICorpGridItem,
} from "./corp-results-grid/corp-results-grid";

interface Props {
  searchValue: string;
  soloColumns: IDataGridColumn[];
  soloRows: IDataGridRow[];
  teamColumns: IDataGridColumn[];
  teamRows: ITeamGridItem[];
  corpColumns: IDataGridColumn[];
  corpRows: ICorpGridItem[];
  onChangeSearch: (payload: IBaseInputChange) => void;
  isTeamGrid: boolean;
  isCorpGrid: boolean;
  soloClassName?: string;
  teamClassName?: string;
  corpClassName?: string;
  getTeamRoleTitle: (role: string) => string;
}

export const PopupGridGroup: FC<Props> = ({
  searchValue,
  soloColumns,
  soloRows,
  teamColumns,
  teamRows,
  corpColumns,
  corpRows,
  onChangeSearch,
  isTeamGrid,
  isCorpGrid,
  soloClassName,
  teamClassName,
  corpClassName,
  getTeamRoleTitle,
}): ReactElement => {
  const { t } = useTranslation();

  const isNotMatch: boolean =
    !soloRows.length && !teamRows.length && !!searchValue;

  const isSoloGrid = !isTeamGrid && !isCorpGrid;

  return (
    <div className="popup-grid-group">
      <div className="popup-grid-group__search">
        <SearchInput
          value={searchValue}
          placeholder={t(
            "assessmentsControl.popup.assessmentResults.input.placeholder.findByName",
          )}
          onChange={onChangeSearch}
        />
      </div>
      <div className="popup-grid-group__container">
        {isSoloGrid && (
          <SoloResultsGrid
            columns={soloColumns}
            rows={soloRows}
            className={soloClassName}
          />
        )}
        {isTeamGrid && (
          <TeamResultsGrid
            columns={teamColumns}
            teams={teamRows}
            className={teamClassName}
            getTeamRoleTitle={getTeamRoleTitle}
          />
        )}
        {isCorpGrid && (
          <CorpResultsGrid
            columns={corpColumns}
            rows={corpRows}
            className={corpClassName}
          />
        )}
        {isNotMatch && (
          <div className="popup-grid-group__empty">
            {t("assessmentsControl.popup.assessmentResults.empty.notFound")}
          </div>
        )}
      </div>
    </div>
  );
};
