import { CSSProperties, memo, ReactElement } from "react";

import { pxToRem } from "../../../helpers/pxToRem";

import { IDataGridImage } from "../../../models/dataGrid";

export const DataGridImage = memo<IDataGridImage>(
  ({ url, alt, width, height }): ReactElement => {
    const styles: CSSProperties = {};

    if (width) {
      styles.width = pxToRem(width);
    }

    if (height) {
      styles.height = pxToRem(height);
    }

    return <img src={url} alt={alt} style={styles} />;
  },
);
