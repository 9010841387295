const removeDash = (str: string) => str?.replace(/-/g, "") || "";

export const togglePlaceParams = <T extends Record<string, string>>(
  queryParams: T,
  newQueryParams: T,
): T => {
  const placeKeys = ["gPlaceFrom", "gPlaceTo"];

  const isAppendOrderByFilter =
    removeDash(queryParams.orderBy).length <
    removeDash(newQueryParams.orderBy).length;

  const isAppendKey =
    Object.keys(queryParams).length < Object.keys(newQueryParams).length;

  let isPlaceAppend = false;

  if (isAppendKey) {
    const difference =
      Object.keys(newQueryParams).find((key) => !queryParams[key]) || "";

    isPlaceAppend = placeKeys.includes(difference);
  }

  if (isAppendOrderByFilter) {
    isPlaceAppend =
      !/-?gPlace/.test(queryParams.orderBy) &&
      /-?gPlace/.test(newQueryParams.orderBy);
  }

  const newFilteredParams = isPlaceAppend
    ? Object.entries(newQueryParams).reduce((res, [key, value]) => {
        if (placeKeys.includes(key)) {
          res[key] = value;
        }

        if (key === "orderBy") {
          res.orderBy = (/-?gPlace/.exec(value) || []).join("");
        }

        return res;
      }, Object.create(null))
    : Object.entries(newQueryParams).reduce((res, [key, value]) => {
        if (!placeKeys.includes(key)) {
          res[key] = value;
        }

        //   if (key === "orderBy") {
        //     res.orderBy = value.replace(/-?gPlace,?/, "");
        //   }
        return res;
      }, Object.create(null));

  return newFilteredParams;
};
