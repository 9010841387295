import { FC, ReactElement } from "react";

import { reflect } from "@effector/reflect";

import { useTranslation } from "react-i18next";

import { BaseCardList, IBaseCardListItem } from "src/shared/components";

import { $cardListItems } from "../../model";

import { openAddSimulationPopup } from "../../lib";
import {
  Permissions,
  useCheckPermission,
} from "../../../../../shared/hooks/useCheckPermission";

interface Props {
  cardListItems: IBaseCardListItem[];
}

const View: FC<Props> = ({ cardListItems }): ReactElement => {
  const { t } = useTranslation();
  const createPermission = useCheckPermission(Permissions.SimulationCreate);

  return (
    <BaseCardList
      cardList={cardListItems}
      addCardProps={{
        onClickGeneral: openAddSimulationPopup,
        title: t("simulationControl.activePage.card.title"),
        btnDisabled: !createPermission,
        btnLabel: t("simulationControl.activePage.card.btn.connect"),
      }}
    />
  );
};

export const SimulationList = reflect<Props>({
  view: View,
  bind: {
    cardListItems: $cardListItems,
  },
});
