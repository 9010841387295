import { FC, ReactElement, useMemo } from "react";

import { Navigation, SwiperOptions } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import { useEvent, useStore, useUnit } from "effector-react";

import { useMediaQuery } from "src/shared/hooks/useMediaQuery";

import { $lvlUpItems } from "../../model";

import { LevelUpCard } from "../";

// import "swiper/modules/effect-coverflow/effect-coverflow.min.css"; //todo: REACT18
import "./level-up-list.scss";
import { assessmentsPlayerCountModel } from "../../../../../entities/public/players-count";

interface Props {}

export const LevelUpList: FC<Props> = (): ReactElement => {
  const lvlUpItems = useUnit($lvlUpItems);

  const { isMobile } = useMediaQuery();

  const sliderProps: SwiperOptions = useMemo(
    () => ({
      navigation: true,
      centeredSlides: isMobile,
      slidesPerView: "auto",
      spaceBetween: isMobile ? 24 : 32,
      effect: isMobile ? "coverflow" : undefined,
      coverflowEffect: isMobile
        ? {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }
        : undefined,
    }),
    [isMobile],
  );

  return (
    <Swiper modules={[Navigation]} className="level-up-list" {...sliderProps}>
      {lvlUpItems.map((lvlUpItemContent) => {
        return (
          <SwiperSlide
            key={lvlUpItemContent.id}
            className="level-up-list__item"
          >
            <LevelUpCard {...lvlUpItemContent} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
