import { ERequestStatus } from "../../types";
import { ProfileCompetenceActions } from "./actionCreators";
import { ProfileCompetenceActionsType } from "./actionTypes";
import { ProfileCompetenceStateInterface } from "./type";

const initialState: ProfileCompetenceStateInterface = {
  profileCompetence: null,
  isSocial: true,
  status: ERequestStatus.NEVER,
};

export const profileCompetenceReducer = (
  state = initialState,
  action: ProfileCompetenceActions,
) => {
  switch (action.type) {
    case ProfileCompetenceActionsType.CLEAN_PROFILE_COMPETENCE:
      return {
        ...state,
        profileCompetence: null,
        status: ERequestStatus.NEVER,
      };
    case ProfileCompetenceActionsType.REQUEST_PROFILE_COMPETENCE:
      return {
        ...state,
        status: ERequestStatus.LOADING,
      };
    case ProfileCompetenceActionsType.SUCCESS_PROFILE_COMPETENCE:
      return {
        ...state,
        profileCompetence: { ...action.payload },
        status: ERequestStatus.LOADED,
      };
    case ProfileCompetenceActionsType.FAILURE_PROFILE_COMPETENCE:
      return {
        ...state,
        status: ERequestStatus.ERROR,
      };
    case ProfileCompetenceActionsType.SET_IS_SOCIAL:
      return {
        ...state,
        isSocial: action.payload,
      };
    default:
      return state;
  }
};
