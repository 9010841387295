import { District, Region, School } from "src/generated/social";

import { EDesiredStudyPlace } from "src/shared/components/user-form/user-form";

import { UserReprAcc } from "src/generated/keycloak";

export const getIsFullProfileWithoutEmailVerified = (
  profile: UserReprAcc | null,
  isHrClientId: boolean,
  isPlayerClientId: boolean,
  dictionariesReady: boolean,
  regions: Region[] | null,
  districts: District[] | null,
  schools: School[] | null,
): boolean | null => {
  if (profile && dictionariesReady) {
    const hasFirstName: boolean = !!profile.firstName;

    const hasLastName: boolean = !!profile.lastName;

    const hasEmail: boolean = !!profile.email;

    const hasBirthdate: boolean = !!profile.attributes?.birthdate?.[0];

    let fullProfile: boolean =
      hasFirstName && hasLastName && hasEmail && hasBirthdate;

    if (isHrClientId || isPlayerClientId) {
      const hasStudyWorkPlace: boolean =
        !!profile.attributes?.studyWorkPlace?.[0];

      const hasCountry: boolean = !!profile.attributes?.country?.[0];

      const hasLocality: boolean = !!profile.attributes?.locality?.[0];

      const isActualRegions: boolean = regions?.length
        ? !!profile.attributes?.region?.[0]
        : true;

      const hasRegion: boolean = regions ? isActualRegions : hasCountry;

      const isActualDistricts: boolean = districts?.length
        ? !!profile.attributes?.district?.[0]
        : true;

      const hasDistrict: boolean = districts ? isActualDistricts : hasRegion;

      fullProfile =
        fullProfile &&
        hasCountry &&
        hasRegion &&
        hasDistrict &&
        hasStudyWorkPlace &&
        hasLocality;

      if (isHrClientId) {
        const hasSupervidesUnit: boolean =
          !!profile.attributes?.supervisedUnit?.[0];

        const hasWorkPhoneNumber: boolean =
          !!profile.attributes?.workPhoneNumber?.[0];

        fullProfile = fullProfile && hasSupervidesUnit && hasWorkPhoneNumber;
      } else if (isPlayerClientId) {
        const desiredStudyPlace = profile.attributes?.desiredStudyPlace?.[0];

        const hasDesiredSpecialty = !!profile.attributes?.desiredSpecialty?.[0];

        const isNotEntrant: boolean =
          desiredStudyPlace === EDesiredStudyPlace.NOT_AN_ENTRANT ||
          desiredStudyPlace === EDesiredStudyPlace.LEADERSHIP_PROGRAM ||
          desiredStudyPlace === EDesiredStudyPlace.ALABUGA_START ||
          desiredStudyPlace === EDesiredStudyPlace.BUSINESS_CATS_OLYMPIAD;

        const isEntrant: boolean = !isNotEntrant && hasDesiredSpecialty;

        const isDesiredStudyPlace: boolean = isNotEntrant || isEntrant;

        const isActualSchools: boolean = schools?.length
          ? !!profile.attributes?.school?.[0]
          : true;

        const hasSchool: boolean = schools ? isActualSchools : hasDistrict;

        fullProfile = fullProfile && isDesiredStudyPlace && hasSchool;
      }
    }

    return fullProfile;
  }

  return null;
};
