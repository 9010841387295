// Stores

import { assessmentsPlayerCountDomain } from "./domain";
import { AssessmentsPlayersCount } from "../../../../generated/game";
import { getPlayersCount } from "../../../../shared/api/hr/assessments";
import { sample } from "effector";

export const $assessmentsPlayerCount =
  assessmentsPlayerCountDomain.createStore<AssessmentsPlayersCount>({
    assessments: [],
  });

export const $isLoading =
  assessmentsPlayerCountDomain.createStore<boolean>(false);

// Events

export const fetchAssessmentsPlayersCountFx =
  assessmentsPlayerCountDomain.createEffect(getPlayersCount);

// Logic

sample({
  clock: fetchAssessmentsPlayersCountFx.pending,
  target: $isLoading,
});

sample({
  clock: fetchAssessmentsPlayersCountFx.doneData,
  fn: (assessments) => assessments || [],
  target: $assessmentsPlayerCount,
});
