import { TDataGridDataParams } from "../../models/dataGrid";

export type GroupRelations = Record<string, string[]>;

export const getActiveGroup = (
  fields: string[],
  groupRelations: GroupRelations,
  queryParams: TDataGridDataParams = {},
  orderByFieldName: string = "orderBy",
  hasEmptyItem = false,
): string => {
  const orderValues = queryParams?.[orderByFieldName]?.split(",") || [];

  const relationGroups = Object.keys(groupRelations).filter((groupKey) => {
    return fields.some((field) => groupRelations[groupKey]?.includes(field));
  });

  const activeSortGroup = relationGroups.find((groupKey) => {
    const values = groupRelations[groupKey];

    return orderValues.some((value) => values.includes(value));
  });

  if (activeSortGroup) {
    return activeSortGroup;
  }

  const activeFiltersGroup = relationGroups.find((groupKey) => {
    return queryParams?.[groupKey];
  });

  if (activeFiltersGroup) {
    return activeFiltersGroup;
  }

  const firstGroup = relationGroups.find((groupKey) => {
    return groupRelations[groupKey].includes(fields[0]);
  });

  const groupRelationsKeys = Object.keys(groupRelations);

  const hasOnlyOneGroup = groupRelationsKeys.length === 1;

  if ((hasOnlyOneGroup || !hasEmptyItem) && firstGroup) {
    return firstGroup;
  }

  return "";
};
