import { IGameType, IRole } from "../store/types";

export interface IMinMaxPlayersGameType {
  min?: number;
  max?: number;
}

export type TMinMaxPlayersGameType = IMinMaxPlayersGameType | undefined;

export type TMinMaxPlayersGameTypeByRoles = Record<
  string,
  IMinMaxPlayersGameType
>;

export enum EMinMaxPlayers {
  SOLO_ALL_PLAYERS = "solo-all-players",
  TEAM_ALL_PLAYERS = "team-all-players",
  TEAM_ALL_TEAMS = "team-all-teams",
}

export enum EMinMaxPlayersByRole {
  SOLO_ALL_PLAYERS_BY_ROLE = "solo-all-players-by-role",
  TEAM_ALL_PLAYERS_BY_ROLE = "team-all-players-by-role",
  TEAM_ALL_TEAMS_BY_ROLE = "team-all-teams-by-role",
}

export const getMinMaxPlayersGameType = (
  gameType: IGameType,
  resultType: EMinMaxPlayers,
): TMinMaxPlayersGameType => {
  const isSolo: boolean = !!gameType.roles;

  const isTeam: boolean = !!gameType.teams;

  if (!isSolo && !isTeam) {
    return;
  }

  const isGetSoloAllPlayers: boolean =
    resultType === EMinMaxPlayers.SOLO_ALL_PLAYERS;

  const isGetTeamAllPlayers: boolean =
    resultType === EMinMaxPlayers.TEAM_ALL_PLAYERS;

  const isGetTeamAllTeams: boolean =
    resultType === EMinMaxPlayers.TEAM_ALL_TEAMS;

  if (isGetSoloAllPlayers) {
    return {
      min: gameType.minPlayers,
      max: gameType.maxPlayers,
    };
  }

  if (isGetTeamAllPlayers) {
    const teamAllPlayersByRole = getMinMaxPlayersGameTypeByRole(
      gameType,
      EMinMaxPlayersByRole.TEAM_ALL_PLAYERS_BY_ROLE,
    );

    if (teamAllPlayersByRole) {
      const teamsValues = Object.values(teamAllPlayersByRole);

      return teamsValues.reduce(
        (final: Required<IMinMaxPlayersGameType>, { min, max }) => {
          if (min !== undefined) {
            final.min += min;
          }

          if (max !== undefined) {
            final.max += max;
          }

          return final;
        },
        { min: 0, max: 0 },
      );
    }
  }

  if (isGetTeamAllTeams && gameType.teams) {
    const teamsValues = Object.values(gameType.teams);

    return teamsValues.reduce(
      (final, { minTeams, maxTeams }) => ({
        min: final.min + minTeams,
        max: final.max + maxTeams,
      }),
      { min: 0, max: 0 },
    );
  }
};

export const getMinMaxPlayersGameTypeByRole = (
  gameType: IGameType,
  resultType: EMinMaxPlayersByRole,
): TMinMaxPlayersGameTypeByRoles | undefined => {
  const isSolo: boolean = !!gameType.roles;

  const isTeam: boolean = !!gameType.teams;

  if (!isSolo && !isTeam) {
    return;
  }

  const isGetSoloAllPlayersByRole: boolean =
    resultType === EMinMaxPlayersByRole.SOLO_ALL_PLAYERS_BY_ROLE;

  const isGetTeamAllPlayersByRole: boolean =
    resultType === EMinMaxPlayersByRole.TEAM_ALL_PLAYERS_BY_ROLE;

  const isGetTeamAllTeamsByRole: boolean =
    resultType === EMinMaxPlayersByRole.TEAM_ALL_TEAMS_BY_ROLE;

  if (isGetSoloAllPlayersByRole && gameType.roles) {
    const minMaxPlayers: TMinMaxPlayersGameTypeByRoles = {};

    const rolesKeys: string[] = Object.keys(gameType.roles);

    rolesKeys.forEach((item) => {
      const role: IRole | undefined = gameType.roles?.[item];

      minMaxPlayers[item] = {
        min: role?.minPlayers,
        max: role?.maxPlayers,
      };
    });

    return minMaxPlayers;
  }

  if (isGetTeamAllPlayersByRole && gameType.teams) {
    const minMaxPlayers: TMinMaxPlayersGameTypeByRoles = {};

    const teamRolesKeys: string[] = Object.keys(gameType.teams);

    teamRolesKeys.forEach((item) => {
      const localTeam = gameType.teams?.[item];

      if (localTeam) {
        const rolesCount: Required<IMinMaxPlayersGameType> = Object.values(
          localTeam.roles,
        ).reduce(
          (
            final: Required<IMinMaxPlayersGameType>,
            { minPlayers, maxPlayers }: IRole,
          ) => {
            if (minPlayers !== undefined) {
              final.min += minPlayers;
            }

            if (maxPlayers !== undefined) {
              final.max += maxPlayers;
            }

            return final;
          },
          { min: 0, max: 0 },
        );

        const minPlayers: number = localTeam.minTeams * rolesCount.min;

        const maxPlayers: number = localTeam.maxTeams * rolesCount.max;

        minMaxPlayers[item] = { min: minPlayers, max: maxPlayers };
      }
    });

    return minMaxPlayers;
  }

  if (isGetTeamAllTeamsByRole && gameType.teams) {
    const minMaxTeams: TMinMaxPlayersGameTypeByRoles = {};

    const teamRolesKeys: string[] = Object.keys(gameType.teams);

    teamRolesKeys.forEach((item) => {
      if (gameType.teams) {
        const { minTeams, maxTeams } = gameType.teams[item];
        minMaxTeams[item] = { min: minTeams, max: maxTeams };
      }
    });

    return minMaxTeams;
  }
};
