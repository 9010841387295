import classNames from "classnames";
import { Dispatch, FC, ReactElement, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseBox,
  BaseInputText,
  FormControl,
  TitleWithContent,
} from "../../../../../shared/components";
import "./info-block.scss";
import { IBaseInputChange } from "../../../../../shared/models";
import { ISimulationTitleData } from "../../lib/types";
import { toCloneObject } from "../../../../../shared/helpers";

interface Props {
  formTitleData: ISimulationTitleData;
  setFormTitleData: Dispatch<SetStateAction<ISimulationTitleData>>;
  errors?: Record<string, string>;
  setFormDataErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  imgUrl: string;
  title: string;
  versionInfo: string;
  description: string;
  className?: string;
}

export const InfoBlock: FC<Props> = ({
  formTitleData,
  setFormTitleData,
  errors,
  setFormDataErrors,
  imgUrl,
  title,
  versionInfo,
  description,
  className,
}): ReactElement => {
  const { t } = useTranslation();

  const onChangeHandler = (payload: IBaseInputChange): void => {
    const inputName = payload.name as keyof ISimulationTitleData;

    setFormTitleData((prev): any => {
      let localPrev: ISimulationTitleData = toCloneObject(prev);

      if (inputName) {
        localPrev[inputName] = payload.value;
      }
      return localPrev;
    });

    setFormDataErrors((prev) => {
      const localPrev = toCloneObject(prev);

      if (inputName) {
        delete localPrev[inputName];
      }

      return localPrev;
    });
  };

  const renderFields = Object.keys(formTitleData).map((item: string) => {
    const typedItem = item as keyof ISimulationTitleData;
    const placeholder = t(
      `simulationControl.simulationPage.infoBlock.simulation.${item}`,
    );
    return (
      <FormControl key={item} error={errors?.[item]}>
        <BaseInputText
          value={formTitleData[typedItem]}
          placeholder={placeholder}
          onChange={onChangeHandler}
          name={item}
          error={!!errors?.[item]}
        />
      </FormControl>
    );
  });

  return (
    <BaseBox className={classNames("info-block", className)}>
      <div className="info-block__item">
        <div className="info-block__container">
          <img
            src={imgUrl}
            alt={title}
            className="info-block__img"
            loading="lazy"
          />
        </div>
      </div>
      {renderFields}
      <div className="info-block__item">
        <TitleWithContent
          title={t("simulationControl.simulationPage.infoBlock.versionInfo")}
        >
          <div className="info-block__item-text">{versionInfo}</div>
        </TitleWithContent>
      </div>
      <div className="info-block__item">
        <TitleWithContent
          title={t("simulationControl.simulationPage.descriptionBlock.title")}
        >
          <div className="info-block__item-text">{description}</div>
        </TitleWithContent>
      </div>
    </BaseBox>
  );
};
