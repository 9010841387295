import React, { useEffect } from "react";
import { Manager } from "../../../generated/social";
import "./contact-card.scss";
import emptyAvatar from "../../../shared/images/avatar-empty.svg";
import { useTranslation } from "react-i18next";
import { languagePicker } from "../../helpers/languagePicker";
import { BaseButton, TextWithIcon } from "..";
import { BaseBox } from "../base-box/base-box";
import { formatPhone } from "../../helpers/phone";
import { getContactAvatarUrl } from "../../helpers/pathToUserFile";

interface Props {
  contactItem: Manager;
  isDeleteDisabled?: boolean;
  onDelete?: (id: string) => void;
}

export const ContactCard: React.FC<Props> = ({
  contactItem,
  onDelete,
}): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const position: string = languagePicker(contactItem.position, i18n.language);
  const [formattedPhoneNumber, setFormattedPhoneNumber] =
    React.useState<string>("");
  const photo: string = !!contactItem.photo
    ? getContactAvatarUrl(contactItem.photo)
    : emptyAvatar;

  useEffect(() => {
    formatPhone(contactItem.phone).then((formattedPhone) => {
      setFormattedPhoneNumber(formattedPhone || "");
    });
  }, [contactItem]);

  return (
    <BaseBox noPadding className="contact-card">
      <div
        className="contact-card__image"
        style={{
          backgroundImage: `url(${photo})`,
        }}
      />
      <div className="contact-card__info">
        <div className="contact-card__info-block">
          <div className="contact-card__position">{position}</div>
          <div className="contact-card__name">{contactItem.fullName}</div>
          <a
            href={`tel:${contactItem.phone}`}
            className="contact-card__link contact-card__link--phone"
          >
            <TextWithIcon
              iconName="phone-gray"
              color="black"
              label={formattedPhoneNumber}
              iconSize={14}
              iconSizeMobile={12}
              labelSize={16}
              labelSizeMobile={14}
              labelWeight="normal"
              isBreakWord
            />
          </a>
          <a
            href={`mailto:${contactItem.email}`}
            className="contact-card__link contact-card__link--mail"
          >
            <TextWithIcon
              iconName="mail-gray"
              color="black"
              label={contactItem.email}
              iconSize={15}
              iconSizeMobile={13}
              labelSize={16}
              labelSizeMobile={14}
              labelWeight="normal"
              isBreakWord
            />
          </a>
        </div>
        {!!onDelete && (
          <div>
            <BaseButton
              className="contact-card__button"
              to={`/other/edit-contacts/${contactItem.id}`}
              lightBlue
              small
            >
              <TextWithIcon
                label={t("other.editContacts.btn.edit")}
                iconName="pen-blue"
              />
            </BaseButton>
            <BaseButton
              className="contact-card__button"
              onClick={() => contactItem.id && onDelete(contactItem.id)}
              lightBlue
              small
            >
              <TextWithIcon
                label={t("other.editContacts.btn.delete")}
                iconName="trash-red"
                color="red"
              />
            </BaseButton>
          </div>
        )}
      </div>
    </BaseBox>
  );
};
