import { ERequestStatus } from "../../types";
import { TSessionsHistoryActions } from "./actionCreators";
import { ESessionsHistoryActions } from "./actionTypes";
import { ISessionsHistoryState } from "./type";

const initialState: ISessionsHistoryState = {
  items: [],
  isReadmore: false,
  pagination: null,
  status: ERequestStatus.LOADING,
  roles: [],
  rolesStatus: ERequestStatus.LOADING,
};

export const sessionsHistoryReducer = (
  state = initialState,
  action: TSessionsHistoryActions,
) => {
  switch (action.type) {
    case ESessionsHistoryActions.CLEAN_SESSIONS_HISTORY:
      return {
        ...state,
        items: [],
        pagination: null,
        status: ERequestStatus.NEVER,
      };
    case ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY:
      return {
        ...state,
        status: ERequestStatus.LOADING,
      };
    case ESessionsHistoryActions.SUCCESS_SESSIONS_HISTORY:
      let items =
        state.isReadmore && action.payload.assessments
          ? [...state.items, ...action.payload.assessments]
          : action.payload.assessments;

      return {
        ...state,
        items,
        pagination: action.payload.pagination,
        status: ERequestStatus.LOADED,
        isReadmore: false,
      };
    case ESessionsHistoryActions.FAILURE_SESSIONS_HISTORY:
      return {
        ...state,
        status: ERequestStatus.ERROR,
        isReadmore: false,
      };
    case ESessionsHistoryActions.REQUEST_SESSIONS_HISTORY_ROLES:
      return {
        ...state,
        rolesStatus: ERequestStatus.LOADING,
      };
    case ESessionsHistoryActions.SUCCESS_SESSIONS_HISTORY_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
        rolesStatus: ERequestStatus.LOADED,
      };
    case ESessionsHistoryActions.FAILURE_SESSIONS_HISTORY_ROLES:
      return {
        ...state,
        rolesStatus: ERequestStatus.ERROR,
      };
    case ESessionsHistoryActions.SET_SESSIONS_HISTORY_IS_READMORE:
      return {
        ...state,
        isReadmore: action.payload.value,
      };
    default:
      return state;
  }
};
