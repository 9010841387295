import classNames from "classnames";
import { FC, ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BaseBox } from "../../../../shared/components";
import { TextWithIcon } from "../../../../shared/components/text-with-icon/text-with-icon";
import { IContactLink } from "../../contacts-page";
import "./contacts-info-block.scss";

interface Props {
  className?: string;
}

const infoBlockDescriptionsValue = {
  phone: "+7 (92749) 61 76 2",
  mail: "games@alabuga.ru",
};

const infoBlockDescriptionsComponents = {
  phone: (
    <a
      href={`tel:${infoBlockDescriptionsValue.phone}`}
      className="contacts-info-block__description-link"
    >
      ...
    </a>
  ),
  mail: (
    <a
      href={`mailto:${infoBlockDescriptionsValue.mail}`}
      className="contacts-info-block__description-link"
    >
      ...
    </a>
  ),
};

const chatsLinks: IContactLink[] = [
  {
    key: "telegram-white",
    link: "https://t.me/joinchat/oZIrwkvGSTQ1YjRi",
    label: "Telegram",
  },
  {
    key: "whats-app-white",
    link: "https://chat.whatsapp.com/CvSJ2lh0M0z0L9ApX1J8QI",
    label: "WhatsApp",
  },
];

export const ContactsInfoBlock: FC<Props> = ({ className }): ReactElement => {
  const { t } = useTranslation();

  return (
    <BaseBox className={classNames("contacts-info-block", className)} noPadding>
      <h3 className="contacts-info-block__title">
        {t("contacts.contactsInfoBlock.title")}
      </h3>
      <div className="contacts-info-block__description">
        <Trans
          i18nKey="contacts.contactsInfoBlock.description"
          components={infoBlockDescriptionsComponents}
          values={infoBlockDescriptionsValue}
        />
      </div>
      <div className="contacts-info-block__chats">
        {chatsLinks.map((item) => (
          <a
            key={item.key}
            className={classNames(
              "contacts-info-block__chats-control",
              `contacts-info-block__chats-control--${item.key}`,
            )}
            href={item.link}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <TextWithIcon
              iconName={item.key}
              label={`${t("contacts.telegramBlock.btn.chatIn")} ${item.label}`}
              iconSize={22}
              iconSizeMobile={15}
              labelSize={16}
              labelSizeMobile={12}
              hideLabel={!item.label}
              color="white"
              isBlock
            />
          </a>
        ))}
      </div>
    </BaseBox>
  );
};
