const config = {
  kc: {
    // Keycloak
    url: parse(process.env.REACT_APP_KC_URL, "http://127.0.0.1:50080/auth"),
    realm: parse(process.env.REACT_APP_KC_REALM, "Alabuga"),
    clientId: parse(process.env.REACT_APP_KC_CLIENT_ID, "alb-hr"),
  },
};

function parse(value, fallback) {
  if (typeof value === "undefined") {
    return fallback;
  }
  switch (typeof fallback) {
    case "boolean":
      return !!JSON.parse(value);
    case "number":
      return JSON.parse(value);
    default:
      return value;
  }
}

export { config };
