/* tslint:disable */
/* eslint-disable */
/**
 * Alabuga Statistics API
 * Alabuga Stats Service<br><a href=\"../static/alb_svc_stats_v1.json\">alb_svc_stats_v1.json</a>
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface AssessmentBriefDto
 */
export interface AssessmentBriefDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  aId: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  gId: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof AssessmentBriefDto
   */
  gTitle?: LocStrDto;
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  aTitle?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  code?: string;
  /**
   *
   * @type {GameType}
   * @memberof AssessmentBriefDto
   */
  gType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof AssessmentBriefDto
   */
  aType: AssessmentType;
  /**
   * Maximum Players count in assessment
   * @type {number}
   * @memberof AssessmentBriefDto
   */
  maxPlayers: number;
  /**
   * Current Players count in assessment
   * @type {number}
   * @memberof AssessmentBriefDto
   */
  curPlayers: number;
  /**
   * Key/value query params to send in a game playUrl
   * @type {object}
   * @memberof AssessmentBriefDto
   */
  playQueryParams?: object;
  /**
   *
   * @type {number}
   * @memberof AssessmentBriefDto
   */
  minRate?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentBriefDto
   */
  maxRate?: number;
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentBriefDto
   */
  finishedAt: string;
  /**
   *
   * @type {AssessmentStatus}
   * @memberof AssessmentBriefDto
   */
  status?: AssessmentStatus;
}
/**
 *
 * @export
 * @interface AssessmentDetailDto
 */
export interface AssessmentDetailDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  aId: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  gId: string;
  /**
   *
   * @type {LocStrDto}
   * @memberof AssessmentDetailDto
   */
  gTitle?: LocStrDto;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  aTitle?: string;
  /**
   *
   * @type {GameType}
   * @memberof AssessmentDetailDto
   */
  gType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof AssessmentDetailDto
   */
  aType: AssessmentType;
  /**
   * Maximum Players count in assessment
   * @type {number}
   * @memberof AssessmentDetailDto
   */
  maxPlayers: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof AssessmentDetailDto
   */
  maxPlayersByRole?: { [key: string]: number };
  /**
   *
   * @type {{ [key: string]: MaxTeams; }}
   * @memberof AssessmentDetailDto
   */
  maxTeamsByName?: { [key: string]: MaxTeams };
  /**
   * Current Players count in assessment
   * @type {number}
   * @memberof AssessmentDetailDto
   */
  curPlayers: number;
  /**
   * Key/value query params to send in a game playUrl
   * @type {object}
   * @memberof AssessmentDetailDto
   */
  playQueryParams?: object;
  /**
   *
   * @type {number}
   * @memberof AssessmentDetailDto
   */
  minRate?: number;
  /**
   *
   * @type {number}
   * @memberof AssessmentDetailDto
   */
  maxRate?: number;
  /**
   *
   * @type {Array<PlayerDto>}
   * @memberof AssessmentDetailDto
   */
  players?: Array<PlayerDto>;
  /**
   *
   * @type {Array<CalcDto>}
   * @memberof AssessmentDetailDto
   */
  calcs?: Array<CalcDto>;
  /**
   *
   * @type {object}
   * @memberof AssessmentDetailDto
   */
  rawMetaJson?: object;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  createdAt: string;
  /**
   *
   * @type {CreatedBy}
   * @memberof AssessmentDetailDto
   */
  createdBy: CreatedBy;
  /**
   *
   * @type {UserInfo}
   * @memberof AssessmentDetailDto
   */
  createdByUser?: UserInfo;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  startedAt?: string;
  /**
   *
   * @type {StartedBy}
   * @memberof AssessmentDetailDto
   */
  startedBy: StartedBy;
  /**
   *
   * @type {UserInfo}
   * @memberof AssessmentDetailDto
   */
  startedByUser?: UserInfo;
  /**
   *
   * @type {AssessmentStatus}
   * @memberof AssessmentDetailDto
   */
  status?: AssessmentStatus;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  finishedAt: string;
  /**
   *
   * @type {string}
   * @memberof AssessmentDetailDto
   */
  code?: string;
  /**
   *
   * @type {object}
   * @memberof AssessmentDetailDto
   */
  params?: object;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum AssessmentStatus {
  Finished = "finished",
  Canceled = "canceled",
}

/**
 *
 * @export
 * @enum {string}
 */
export enum AssessmentType {
  Rating = "rating",
  NonRating = "nonRating",
}

/**
 *
 * @export
 * @interface AssessmentsBriefResDto
 */
export interface AssessmentsBriefResDto {
  /**
   *
   * @type {Array<AssessmentBriefDto>}
   * @memberof AssessmentsBriefResDto
   */
  assessments?: Array<AssessmentBriefDto>;
  /**
   *
   * @type {Pagination}
   * @memberof AssessmentsBriefResDto
   */
  pagination: Pagination;
}
/**
 *
 * @export
 * @interface CalcDto
 */
export interface CalcDto {
  /**
   *
   * @type {string}
   * @memberof CalcDto
   */
  formula: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum Competence {
  Analytics = "analytics",
  AnalyticsTenacity = "analyticsTenacity",
  Command = "command",
  CommandTenacity = "commandTenacity",
  Communication = "communication",
  CommunicationTenacity = "communicationTenacity",
  Thinking3d = "thinking3d",
  Thinking3dTenacity = "thinking3dTenacity",
  Economics = "economics",
  EconomicsTenacity = "economicsTenacity",
  Jurisprudence = "jurisprudence",
  JurisprudenceTenacity = "jurisprudenceTenacity",
  Microelectronics = "microelectronics",
  MicroelectronicsTenacity = "microelectronicsTenacity",
}

/**
 *
 * @export
 * @interface CompetenceDiff
 */
export interface CompetenceDiff {
  /**
   *
   * @type {Competence}
   * @memberof CompetenceDiff
   */
  competence: Competence;
  /**
   * Rate change diff
   * @type {number}
   * @memberof CompetenceDiff
   */
  diff: number;
}
/**
 *
 * @export
 * @interface CompetenceRate
 */
export interface CompetenceRate {
  /**
   *
   * @type {Competence}
   * @memberof CompetenceRate
   */
  competence: Competence;
  /**
   *
   * @type {number}
   * @memberof CompetenceRate
   */
  rate: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CreatedBy {
  Auto = "auto",
  User = "user",
}

/**
 *
 * @export
 * @interface Dislike
 */
export interface Dislike {
  /**
   * Player ID who dislikes
   * @type {string}
   * @memberof Dislike
   */
  pId: string;
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  middlename?: string;
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  teamName?: string;
  /**
   *
   * @type {string}
   * @memberof Dislike
   */
  teamNum?: string;
  /**
   * Player whom dislike rate changes diff
   * @type {Array<CompetenceDiff>}
   * @memberof Dislike
   */
  diffs: Array<CompetenceDiff>;
}
/**
 *
 * @export
 * @interface DislikeUpd
 */
export interface DislikeUpd {
  /**
   *
   * @type {string}
   * @memberof DislikeUpd
   */
  who: string;
  /**
   *
   * @type {string}
   * @memberof DislikeUpd
   */
  whom: string;
  /**
   *
   * @type {Array<CompetenceDiff>}
   * @memberof DislikeUpd
   */
  diffs: Array<CompetenceDiff>;
}
/**
 *
 * @export
 * @interface Dislikes
 */
export interface Dislikes {
  /**
   *
   * @type {Array<Dislike>}
   * @memberof Dislikes
   */
  dislikes: Array<Dislike>;
}
/**
 *
 * @export
 * @interface DislikesBulkUpd
 */
export interface DislikesBulkUpd {
  /**
   *
   * @type {Array<DislikeUpd>}
   * @memberof DislikesBulkUpd
   */
  dislikes: Array<DislikeUpd>;
}
/**
 *
 * @export
 * @interface ErrDto
 */
export interface ErrDto {
  /**
   * Response error code >= 1
   * @type {number}
   * @memberof ErrDto
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof ErrDto
   */
  msg?: string;
}
/**
 *
 * @export
 * @interface ErrResDto
 */
export interface ErrResDto {
  /**
   *
   * @type {ErrDto}
   * @memberof ErrResDto
   */
  err: ErrDto;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum GameType {
  Tutorial = "tutorial",
  TutorialLtd = "tutorial_ltd",
  Single = "single",
  Solo = "solo",
  Team = "team",
  Corp = "corp",
}

/**
 * Localized strings
 * @export
 * @interface LocStrDto
 */
export interface LocStrDto {
  /**
   *
   * @type {string}
   * @memberof LocStrDto
   */
  en?: string;
  /**
   *
   * @type {string}
   * @memberof LocStrDto
   */
  ru?: string;
}
/**
 *
 * @export
 * @interface MaxTeams
 */
export interface MaxTeams {
  /**
   *
   * @type {number}
   * @memberof MaxTeams
   */
  maxTeams?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof MaxTeams
   */
  maxPlayersByRole?: { [key: string]: number };
}
/**
 *
 * @export
 * @interface PAssessmentBriefDto
 */
export interface PAssessmentBriefDto {
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  aId: string;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  gId: string;
  /**
   *
   * @type {object}
   * @memberof PAssessmentBriefDto
   */
  rawMetaJson?: object;
  /**
   *
   * @type {LocStrDto}
   * @memberof PAssessmentBriefDto
   */
  gTitle?: LocStrDto;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  aTitle?: string;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  code?: string;
  /**
   *
   * @type {GameType}
   * @memberof PAssessmentBriefDto
   */
  gType: GameType;
  /**
   *
   * @type {AssessmentType}
   * @memberof PAssessmentBriefDto
   */
  aType: AssessmentType;
  /**
   * Maximum Players count in assessment
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  maxPlayers: number;
  /**
   * Current Players count in assessment
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  curPlayers: number;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  finishedAt: string;
  /**
   *
   * @type {string}
   * @memberof PAssessmentBriefDto
   */
  role?: string;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  place?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  analyticsDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  communicationDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  commandDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  analyticsTenacityDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  communicationTenacityDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  commandTenacityDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  thinking3dDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  thinking3dTenacityDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  economicsDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  economicsTenacityDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  jurisprudenceDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  jurisprudenceTenacityDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  microelectronicsDiff?: number;
  /**
   *
   * @type {number}
   * @memberof PAssessmentBriefDto
   */
  microelectronicsTenacityDiff?: number;
  /**
   *
   * @type {Array<PDislike>}
   * @memberof PAssessmentBriefDto
   */
  dislikes?: Array<PDislike>;
}
/**
 *
 * @export
 * @interface PAssessmentsBriefResDto
 */
export interface PAssessmentsBriefResDto {
  /**
   *
   * @type {Array<PAssessmentBriefDto>}
   * @memberof PAssessmentsBriefResDto
   */
  assessments?: Array<PAssessmentBriefDto>;
  /**
   *
   * @type {Pagination}
   * @memberof PAssessmentsBriefResDto
   */
  pagination?: Pagination;
}
/**
 *
 * @export
 * @interface PDislike
 */
export interface PDislike {
  /**
   *
   * @type {string}
   * @memberof PDislike
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof PDislike
   */
  teamName?: string;
  /**
   *
   * @type {string}
   * @memberof PDislike
   */
  teamNum?: string;
  /**
   * Player whom dislike rate changes diff
   * @type {Array<CompetenceDiff>}
   * @memberof PDislike
   */
  diffs: Array<CompetenceDiff>;
}
/**
 *
 * @export
 * @interface PRoleDto
 */
export interface PRoleDto {
  /**
   *
   * @type {string}
   * @memberof PRoleDto
   */
  role: string;
}
/**
 *
 * @export
 * @interface PRolesResDto
 */
export interface PRolesResDto {
  /**
   *
   * @type {Array<PRoleDto>}
   * @memberof PRolesResDto
   */
  roles: Array<PRoleDto>;
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageNum: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  totalItems: number;
}
/**
 *
 * @export
 * @interface PlayerDto
 */
export interface PlayerDto {
  /**
   *
   * @type {string}
   * @memberof PlayerDto
   */
  pId: string;
  /**
   *
   * @type {string}
   * @memberof PlayerDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerDto
   */
  quitAt?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerDto
   */
  role?: string;
  /**
   *
   * @type {string}
   * @memberof PlayerDto
   */
  teamName?: string;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  teamNum?: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  areaNum?: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  corpNum?: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  place?: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  analytics: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  analyticsDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  analyticsTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  analyticsTenacityDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  communication: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  communicationDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  communicationTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  communicationTenacityDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  command: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  commandDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  commandTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  commandTenacityDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  thinking3d: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  thinking3dDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  thinking3dTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  thinking3dTenacityDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  economics: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  economicsDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  economicsTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  economicsTenacityDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  jurisprudence: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  jurisprudenceDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  jurisprudenceTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  jurisprudenceTenacityDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  microelectronics: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  microelectronicsDiff: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  microelectronicsTenacity: number;
  /**
   *
   * @type {number}
   * @memberof PlayerDto
   */
  microelectronicsTenacityDiff: number;
  /**
   * Since meta v1.3. JSON object with additional results dictionary. Keys -- same as in `meta.gameTypes.xxx.results` keys. Values -- single value having same type as in `meta.gameTypes.xxx.results[n].type`.
   * @type {object}
   * @memberof PlayerDto
   */
  results?: object;
  /**
   *
   * @type {Array<Dislike>}
   * @memberof PlayerDto
   */
  dislikes?: Array<Dislike>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum StartedBy {
  Auto = "auto",
  User = "user",
  Unknown = "unknown",
}

/**
 *
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof UserInfo
   */
  lastname: string;
}

/**
 * AssessmentApi - axios parameter creator
 * @export
 */
export const AssessmentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessment: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("getAssessment", "assessmentId", assessmentId);
      const localVarPath = `/api/v1/assessments/{assessment_id}`.replace(
        `{${"assessment_id"}}`,
        encodeURIComponent(String(assessmentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get All Assessments
     * @param {Array<string>} [pId] Player IDs in Query
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<string>} [code] Code
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;gType&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60; /&#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<'finished' | 'canceled'>} [status] Assessment Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessments: async (
      pId?: Array<string>,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      aTitle?: Array<string>,
      code?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      firstName?: string,
      lastName?: string,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      status?: Array<"finished" | "canceled">,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/assessments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (pId) {
        localVarQueryParameter["pId"] = pId;
      }

      if (gTitle) {
        localVarQueryParameter["gTitle"] = gTitle;
      }

      if (gType) {
        localVarQueryParameter["gType"] = gType;
      }

      if (aTitle) {
        localVarQueryParameter["aTitle"] = aTitle;
      }

      if (code) {
        localVarQueryParameter["code"] = code;
      }

      if (aType) {
        localVarQueryParameter["aType"] = aType;
      }

      if (curPlayersFrom !== undefined) {
        localVarQueryParameter["curPlayersFrom"] = curPlayersFrom;
      }

      if (curPlayersTo !== undefined) {
        localVarQueryParameter["curPlayersTo"] = curPlayersTo;
      }

      if (maxPlayersFrom !== undefined) {
        localVarQueryParameter["maxPlayersFrom"] = maxPlayersFrom;
      }

      if (maxPlayersTo !== undefined) {
        localVarQueryParameter["maxPlayersTo"] = maxPlayersTo;
      }

      if (firstName !== undefined) {
        localVarQueryParameter["firstName"] = firstName;
      }

      if (lastName !== undefined) {
        localVarQueryParameter["lastName"] = lastName;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] =
          (createdFrom as any) instanceof Date
            ? (createdFrom as any).toISOString()
            : createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] =
          (createdTo as any) instanceof Date
            ? (createdTo as any).toISOString()
            : createdTo;
      }

      if (startedFrom !== undefined) {
        localVarQueryParameter["startedFrom"] =
          (startedFrom as any) instanceof Date
            ? (startedFrom as any).toISOString()
            : startedFrom;
      }

      if (startedTo !== undefined) {
        localVarQueryParameter["startedTo"] =
          (startedTo as any) instanceof Date
            ? (startedTo as any).toISOString()
            : startedTo;
      }

      if (finishedFrom !== undefined) {
        localVarQueryParameter["finishedFrom"] =
          (finishedFrom as any) instanceof Date
            ? (finishedFrom as any).toISOString()
            : finishedFrom;
      }

      if (finishedTo !== undefined) {
        localVarQueryParameter["finishedTo"] =
          (finishedTo as any) instanceof Date
            ? (finishedTo as any).toISOString()
            : finishedTo;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Assessment Dislikes
     * @param {string} playerId Player ID
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPAssessmentDislikes: async (
      playerId: string,
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("getPAssessmentDislikes", "playerId", playerId);
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists("getPAssessmentDislikes", "assessmentId", assessmentId);
      const localVarPath =
        `/api/v1/players/{player_id}/assessments/{assessment_id}/dislikes`
          .replace(`{${"player_id"}}`, encodeURIComponent(String(playerId)))
          .replace(
            `{${"assessment_id"}}`,
            encodeURIComponent(String(assessmentId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Assessment history
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [analyticsTenacityDiffFrom]
     * @param {number} [analyticsTenacityDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [communicationTenacityDiffFrom]
     * @param {number} [communicationTenacityDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [commandTenacityDiffFrom]
     * @param {number} [commandTenacityDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [thinking3dTenacityDiffFrom]
     * @param {number} [thinking3dTenacityDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [economicsTenacityDiffFrom]
     * @param {number} [economicsTenacityDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [jurisprudenceTenacityDiffFrom]
     * @param {number} [jurisprudenceTenacityDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {number} [microelectronicsTenacityDiffFrom]
     * @param {number} [microelectronicsTenacityDiffTo]
     * @param {Array<string>} [tenacityCompetenceNullQuery]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPAssessments: async (
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      analyticsTenacityDiffFrom?: number,
      analyticsTenacityDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      communicationTenacityDiffFrom?: number,
      communicationTenacityDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      commandTenacityDiffFrom?: number,
      commandTenacityDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      thinking3dTenacityDiffFrom?: number,
      thinking3dTenacityDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      economicsTenacityDiffFrom?: number,
      economicsTenacityDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      jurisprudenceTenacityDiffFrom?: number,
      jurisprudenceTenacityDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      microelectronicsTenacityDiffFrom?: number,
      microelectronicsTenacityDiffTo?: number,
      tenacityCompetenceNullQuery?: Array<string>,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("getPAssessments", "playerId", playerId);
      const localVarPath = `/api/v1/players/{player_id}/assessments`.replace(
        `{${"player_id"}}`,
        encodeURIComponent(String(playerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (aTitle) {
        localVarQueryParameter["aTitle"] = aTitle;
      }

      if (aType) {
        localVarQueryParameter["aType"] = aType;
      }

      if (gTitle) {
        localVarQueryParameter["gTitle"] = gTitle;
      }

      if (gType) {
        localVarQueryParameter["gType"] = gType;
      }

      if (code) {
        localVarQueryParameter["code"] = code;
      }

      if (curPlayersFrom !== undefined) {
        localVarQueryParameter["curPlayersFrom"] = curPlayersFrom;
      }

      if (curPlayersTo !== undefined) {
        localVarQueryParameter["curPlayersTo"] = curPlayersTo;
      }

      if (maxPlayersFrom !== undefined) {
        localVarQueryParameter["maxPlayersFrom"] = maxPlayersFrom;
      }

      if (maxPlayersTo !== undefined) {
        localVarQueryParameter["maxPlayersTo"] = maxPlayersTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] =
          (createdFrom as any) instanceof Date
            ? (createdFrom as any).toISOString()
            : createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] =
          (createdTo as any) instanceof Date
            ? (createdTo as any).toISOString()
            : createdTo;
      }

      if (startedFrom !== undefined) {
        localVarQueryParameter["startedFrom"] =
          (startedFrom as any) instanceof Date
            ? (startedFrom as any).toISOString()
            : startedFrom;
      }

      if (startedTo !== undefined) {
        localVarQueryParameter["startedTo"] =
          (startedTo as any) instanceof Date
            ? (startedTo as any).toISOString()
            : startedTo;
      }

      if (finishedFrom !== undefined) {
        localVarQueryParameter["finishedFrom"] =
          (finishedFrom as any) instanceof Date
            ? (finishedFrom as any).toISOString()
            : finishedFrom;
      }

      if (finishedTo !== undefined) {
        localVarQueryParameter["finishedTo"] =
          (finishedTo as any) instanceof Date
            ? (finishedTo as any).toISOString()
            : finishedTo;
      }

      if (role) {
        localVarQueryParameter["role"] = role;
      }

      if (placeFrom !== undefined) {
        localVarQueryParameter["placeFrom"] = placeFrom;
      }

      if (placeTo !== undefined) {
        localVarQueryParameter["placeTo"] = placeTo;
      }

      if (analyticsDiffFrom !== undefined) {
        localVarQueryParameter["analyticsDiffFrom"] = analyticsDiffFrom;
      }

      if (analyticsDiffTo !== undefined) {
        localVarQueryParameter["analyticsDiffTo"] = analyticsDiffTo;
      }

      if (analyticsTenacityDiffFrom !== undefined) {
        localVarQueryParameter["analyticsTenacityDiffFrom"] =
          analyticsTenacityDiffFrom;
      }

      if (analyticsTenacityDiffTo !== undefined) {
        localVarQueryParameter["analyticsTenacityDiffTo"] =
          analyticsTenacityDiffTo;
      }

      if (communicationDiffFrom !== undefined) {
        localVarQueryParameter["communicationDiffFrom"] = communicationDiffFrom;
      }

      if (communicationDiffTo !== undefined) {
        localVarQueryParameter["communicationDiffTo"] = communicationDiffTo;
      }

      if (communicationTenacityDiffFrom !== undefined) {
        localVarQueryParameter["communicationTenacityDiffFrom"] =
          communicationTenacityDiffFrom;
      }

      if (communicationTenacityDiffTo !== undefined) {
        localVarQueryParameter["communicationTenacityDiffTo"] =
          communicationTenacityDiffTo;
      }

      if (commandDiffFrom !== undefined) {
        localVarQueryParameter["commandDiffFrom"] = commandDiffFrom;
      }

      if (commandDiffTo !== undefined) {
        localVarQueryParameter["commandDiffTo"] = commandDiffTo;
      }

      if (commandTenacityDiffFrom !== undefined) {
        localVarQueryParameter["commandTenacityDiffFrom"] =
          commandTenacityDiffFrom;
      }

      if (commandTenacityDiffTo !== undefined) {
        localVarQueryParameter["commandTenacityDiffTo"] = commandTenacityDiffTo;
      }

      if (thinking3dDiffFrom !== undefined) {
        localVarQueryParameter["thinking3dDiffFrom"] = thinking3dDiffFrom;
      }

      if (thinking3dDiffTo !== undefined) {
        localVarQueryParameter["thinking3dDiffTo"] = thinking3dDiffTo;
      }

      if (thinking3dTenacityDiffFrom !== undefined) {
        localVarQueryParameter["thinking3dTenacityDiffFrom"] =
          thinking3dTenacityDiffFrom;
      }

      if (thinking3dTenacityDiffTo !== undefined) {
        localVarQueryParameter["thinking3dTenacityDiffTo"] =
          thinking3dTenacityDiffTo;
      }

      if (economicsDiffFrom !== undefined) {
        localVarQueryParameter["economicsDiffFrom"] = economicsDiffFrom;
      }

      if (economicsDiffTo !== undefined) {
        localVarQueryParameter["economicsDiffTo"] = economicsDiffTo;
      }

      if (economicsTenacityDiffFrom !== undefined) {
        localVarQueryParameter["economicsTenacityDiffFrom"] =
          economicsTenacityDiffFrom;
      }

      if (economicsTenacityDiffTo !== undefined) {
        localVarQueryParameter["economicsTenacityDiffTo"] =
          economicsTenacityDiffTo;
      }

      if (jurisprudenceDiffFrom !== undefined) {
        localVarQueryParameter["jurisprudenceDiffFrom"] = jurisprudenceDiffFrom;
      }

      if (jurisprudenceDiffTo !== undefined) {
        localVarQueryParameter["jurisprudenceDiffTo"] = jurisprudenceDiffTo;
      }

      if (jurisprudenceTenacityDiffFrom !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityDiffFrom"] =
          jurisprudenceTenacityDiffFrom;
      }

      if (jurisprudenceTenacityDiffTo !== undefined) {
        localVarQueryParameter["jurisprudenceTenacityDiffTo"] =
          jurisprudenceTenacityDiffTo;
      }

      if (microelectronicsDiffFrom !== undefined) {
        localVarQueryParameter["microelectronicsDiffFrom"] =
          microelectronicsDiffFrom;
      }

      if (microelectronicsDiffTo !== undefined) {
        localVarQueryParameter["microelectronicsDiffTo"] =
          microelectronicsDiffTo;
      }

      if (microelectronicsTenacityDiffFrom !== undefined) {
        localVarQueryParameter["microelectronicsTenacityDiffFrom"] =
          microelectronicsTenacityDiffFrom;
      }

      if (microelectronicsTenacityDiffTo !== undefined) {
        localVarQueryParameter["microelectronicsTenacityDiffTo"] =
          microelectronicsTenacityDiffTo;
      }

      if (tenacityCompetenceNullQuery) {
        localVarQueryParameter["tenacityCompetenceNullQuery"] =
          tenacityCompetenceNullQuery;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Player Assessment history roles list
     * @param {string} playerId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPRoles: async (
      playerId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("getPRoles", "playerId", playerId);
      const localVarPath =
        `/api/v1/players/{player_id}/assessments/roles`.replace(
          `{${"player_id"}}`,
          encodeURIComponent(String(playerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add an Assessment with players/results/calcs
     * @param {AssessmentDetailDto} assessment Post Assessment with players/results/calcs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessment: async (
      assessment: AssessmentDetailDto,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessment' is not null or undefined
      assertParamExists("postAssessment", "assessment", assessment);
      const localVarPath = `/api/v1/assessments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth_svc",
        [],
        configuration,
      );

      // authentication kc_local_auth_svc required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth_svc",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assessment,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add an Assessment dislikes
     * @param {string} assessmentId Assessment ID
     * @param {DislikesBulkUpd} dislikes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessmentDislikesBulkUpd: async (
      assessmentId: string,
      dislikes: DislikesBulkUpd,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "postAssessmentDislikesBulkUpd",
        "assessmentId",
        assessmentId,
      );
      // verify required parameter 'dislikes' is not null or undefined
      assertParamExists("postAssessmentDislikesBulkUpd", "dislikes", dislikes);
      const localVarPath =
        `/api/v1/assessments/{assessment_id}/dislikes/bulkUpd`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dislikes,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssessmentApi - functional programming interface
 * @export
 */
export const AssessmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AssessmentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssessment(
      assessmentId: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentDetailDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessment(
        assessmentId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get All Assessments
     * @param {Array<string>} [pId] Player IDs in Query
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<string>} [code] Code
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;gType&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60; /&#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<'finished' | 'canceled'>} [status] Assessment Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssessments(
      pId?: Array<string>,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      aTitle?: Array<string>,
      code?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      firstName?: string,
      lastName?: string,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      status?: Array<"finished" | "canceled">,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentsBriefResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssessments(
        pId,
        gTitle,
        gType,
        aTitle,
        code,
        aType,
        curPlayersFrom,
        curPlayersTo,
        maxPlayersFrom,
        maxPlayersTo,
        firstName,
        lastName,
        createdFrom,
        createdTo,
        startedFrom,
        startedTo,
        finishedFrom,
        finishedTo,
        orderBy,
        pageNum,
        pageSize,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Assessment Dislikes
     * @param {string} playerId Player ID
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPAssessmentDislikes(
      playerId: string,
      assessmentId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dislikes>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPAssessmentDislikes(
          playerId,
          assessmentId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Assessment history
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [analyticsTenacityDiffFrom]
     * @param {number} [analyticsTenacityDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [communicationTenacityDiffFrom]
     * @param {number} [communicationTenacityDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [commandTenacityDiffFrom]
     * @param {number} [commandTenacityDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [thinking3dTenacityDiffFrom]
     * @param {number} [thinking3dTenacityDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [economicsTenacityDiffFrom]
     * @param {number} [economicsTenacityDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [jurisprudenceTenacityDiffFrom]
     * @param {number} [jurisprudenceTenacityDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {number} [microelectronicsTenacityDiffFrom]
     * @param {number} [microelectronicsTenacityDiffTo]
     * @param {Array<string>} [tenacityCompetenceNullQuery]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPAssessments(
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      analyticsTenacityDiffFrom?: number,
      analyticsTenacityDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      communicationTenacityDiffFrom?: number,
      communicationTenacityDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      commandTenacityDiffFrom?: number,
      commandTenacityDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      thinking3dTenacityDiffFrom?: number,
      thinking3dTenacityDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      economicsTenacityDiffFrom?: number,
      economicsTenacityDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      jurisprudenceTenacityDiffFrom?: number,
      jurisprudenceTenacityDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      microelectronicsTenacityDiffFrom?: number,
      microelectronicsTenacityDiffTo?: number,
      tenacityCompetenceNullQuery?: Array<string>,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PAssessmentsBriefResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPAssessments(
        playerId,
        aTitle,
        aType,
        gTitle,
        gType,
        code,
        curPlayersFrom,
        curPlayersTo,
        maxPlayersFrom,
        maxPlayersTo,
        createdFrom,
        createdTo,
        startedFrom,
        startedTo,
        finishedFrom,
        finishedTo,
        role,
        placeFrom,
        placeTo,
        analyticsDiffFrom,
        analyticsDiffTo,
        analyticsTenacityDiffFrom,
        analyticsTenacityDiffTo,
        communicationDiffFrom,
        communicationDiffTo,
        communicationTenacityDiffFrom,
        communicationTenacityDiffTo,
        commandDiffFrom,
        commandDiffTo,
        commandTenacityDiffFrom,
        commandTenacityDiffTo,
        thinking3dDiffFrom,
        thinking3dDiffTo,
        thinking3dTenacityDiffFrom,
        thinking3dTenacityDiffTo,
        economicsDiffFrom,
        economicsDiffTo,
        economicsTenacityDiffFrom,
        economicsTenacityDiffTo,
        jurisprudenceDiffFrom,
        jurisprudenceDiffTo,
        jurisprudenceTenacityDiffFrom,
        jurisprudenceTenacityDiffTo,
        microelectronicsDiffFrom,
        microelectronicsDiffTo,
        microelectronicsTenacityDiffFrom,
        microelectronicsTenacityDiffTo,
        tenacityCompetenceNullQuery,
        orderBy,
        pageNum,
        pageSize,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Player Assessment history roles list
     * @param {string} playerId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPRoles(
      playerId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PRolesResDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPRoles(
        playerId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add an Assessment with players/results/calcs
     * @param {AssessmentDetailDto} assessment Post Assessment with players/results/calcs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAssessment(
      assessment: AssessmentDetailDto,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AssessmentDetailDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAssessment(
        assessment,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add an Assessment dislikes
     * @param {string} assessmentId Assessment ID
     * @param {DislikesBulkUpd} dislikes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAssessmentDislikesBulkUpd(
      assessmentId: string,
      dislikes: DislikesBulkUpd,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.postAssessmentDislikesBulkUpd(
          assessmentId,
          dislikes,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssessmentApi - factory interface
 * @export
 */
export const AssessmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssessmentApiFp(configuration);
  return {
    /**
     *
     * @summary Get an Assessment
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessment(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<AssessmentDetailDto> {
      return localVarFp
        .getAssessment(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get All Assessments
     * @param {Array<string>} [pId] Player IDs in Query
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<string>} [code] Code
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [firstName] First Name
     * @param {string} [lastName] Last Name
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;gType&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60; /&#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {Array<'finished' | 'canceled'>} [status] Assessment Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssessments(
      pId?: Array<string>,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      aTitle?: Array<string>,
      code?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      firstName?: string,
      lastName?: string,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      status?: Array<"finished" | "canceled">,
      options?: any,
    ): AxiosPromise<AssessmentsBriefResDto> {
      return localVarFp
        .getAssessments(
          pId,
          gTitle,
          gType,
          aTitle,
          code,
          aType,
          curPlayersFrom,
          curPlayersTo,
          maxPlayersFrom,
          maxPlayersTo,
          firstName,
          lastName,
          createdFrom,
          createdTo,
          startedFrom,
          startedTo,
          finishedFrom,
          finishedTo,
          orderBy,
          pageNum,
          pageSize,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Assessment Dislikes
     * @param {string} playerId Player ID
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPAssessmentDislikes(
      playerId: string,
      assessmentId: string,
      options?: any,
    ): AxiosPromise<Dislikes> {
      return localVarFp
        .getPAssessmentDislikes(playerId, assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Assessment history
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [analyticsTenacityDiffFrom]
     * @param {number} [analyticsTenacityDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [communicationTenacityDiffFrom]
     * @param {number} [communicationTenacityDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [commandTenacityDiffFrom]
     * @param {number} [commandTenacityDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [thinking3dTenacityDiffFrom]
     * @param {number} [thinking3dTenacityDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [economicsTenacityDiffFrom]
     * @param {number} [economicsTenacityDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [jurisprudenceTenacityDiffFrom]
     * @param {number} [jurisprudenceTenacityDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {number} [microelectronicsTenacityDiffFrom]
     * @param {number} [microelectronicsTenacityDiffTo]
     * @param {Array<string>} [tenacityCompetenceNullQuery]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPAssessments(
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      analyticsTenacityDiffFrom?: number,
      analyticsTenacityDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      communicationTenacityDiffFrom?: number,
      communicationTenacityDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      commandTenacityDiffFrom?: number,
      commandTenacityDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      thinking3dTenacityDiffFrom?: number,
      thinking3dTenacityDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      economicsTenacityDiffFrom?: number,
      economicsTenacityDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      jurisprudenceTenacityDiffFrom?: number,
      jurisprudenceTenacityDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      microelectronicsTenacityDiffFrom?: number,
      microelectronicsTenacityDiffTo?: number,
      tenacityCompetenceNullQuery?: Array<string>,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<PAssessmentsBriefResDto> {
      return localVarFp
        .getPAssessments(
          playerId,
          aTitle,
          aType,
          gTitle,
          gType,
          code,
          curPlayersFrom,
          curPlayersTo,
          maxPlayersFrom,
          maxPlayersTo,
          createdFrom,
          createdTo,
          startedFrom,
          startedTo,
          finishedFrom,
          finishedTo,
          role,
          placeFrom,
          placeTo,
          analyticsDiffFrom,
          analyticsDiffTo,
          analyticsTenacityDiffFrom,
          analyticsTenacityDiffTo,
          communicationDiffFrom,
          communicationDiffTo,
          communicationTenacityDiffFrom,
          communicationTenacityDiffTo,
          commandDiffFrom,
          commandDiffTo,
          commandTenacityDiffFrom,
          commandTenacityDiffTo,
          thinking3dDiffFrom,
          thinking3dDiffTo,
          thinking3dTenacityDiffFrom,
          thinking3dTenacityDiffTo,
          economicsDiffFrom,
          economicsDiffTo,
          economicsTenacityDiffFrom,
          economicsTenacityDiffTo,
          jurisprudenceDiffFrom,
          jurisprudenceDiffTo,
          jurisprudenceTenacityDiffFrom,
          jurisprudenceTenacityDiffTo,
          microelectronicsDiffFrom,
          microelectronicsDiffTo,
          microelectronicsTenacityDiffFrom,
          microelectronicsTenacityDiffTo,
          tenacityCompetenceNullQuery,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Player Assessment history roles list
     * @param {string} playerId Player ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPRoles(playerId: string, options?: any): AxiosPromise<PRolesResDto> {
      return localVarFp
        .getPRoles(playerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add an Assessment with players/results/calcs
     * @param {AssessmentDetailDto} assessment Post Assessment with players/results/calcs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessment(
      assessment: AssessmentDetailDto,
      options?: any,
    ): AxiosPromise<AssessmentDetailDto> {
      return localVarFp
        .postAssessment(assessment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add an Assessment dislikes
     * @param {string} assessmentId Assessment ID
     * @param {DislikesBulkUpd} dislikes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssessmentDislikesBulkUpd(
      assessmentId: string,
      dislikes: DislikesBulkUpd,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postAssessmentDislikesBulkUpd(assessmentId, dislikes, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAssessment operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetAssessmentRequest
 */
export interface AssessmentApiGetAssessmentRequest {
  /**
   * Assessment ID
   * @type {string}
   * @memberof AssessmentApiGetAssessment
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for getAssessments operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetAssessmentsRequest
 */
export interface AssessmentApiGetAssessmentsRequest {
  /**
   * Player IDs in Query
   * @type {Array<string>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly pId?: Array<string>;

  /**
   * Game Title
   * @type {Array<string>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly gTitle?: Array<string>;

  /**
   * Game Types
   * @type {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly gType?: Array<
    "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
  >;

  /**
   * Assessment Title
   * @type {Array<string>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly aTitle?: Array<string>;

  /**
   * Code
   * @type {Array<string>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly code?: Array<string>;

  /**
   * Assessment Type
   * @type {Array<'rating' | 'nonRating'>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly aType?: Array<"rating" | "nonRating">;

  /**
   * Current Players Count From
   * @type {number}
   * @memberof AssessmentApiGetAssessments
   */
  readonly curPlayersFrom?: number;

  /**
   * Current Players Count To
   * @type {number}
   * @memberof AssessmentApiGetAssessments
   */
  readonly curPlayersTo?: number;

  /**
   * Maximum Players Count From
   * @type {number}
   * @memberof AssessmentApiGetAssessments
   */
  readonly maxPlayersFrom?: number;

  /**
   * Maximum Players Count To
   * @type {number}
   * @memberof AssessmentApiGetAssessments
   */
  readonly maxPlayersTo?: number;

  /**
   * First Name
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly firstName?: string;

  /**
   * Last Name
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly lastName?: string;

  /**
   * Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly createdFrom?: string;

  /**
   * Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly createdTo?: string;

  /**
   * Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly startedFrom?: string;

  /**
   * Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly startedTo?: string;

  /**
   * Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly finishedFrom?: string;

  /**
   * Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly finishedTo?: string;

  /**
   * sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;gType&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60; /&#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof AssessmentApiGetAssessments
   */
  readonly orderBy?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof AssessmentApiGetAssessments
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof AssessmentApiGetAssessments
   */
  readonly pageSize?: number;

  /**
   * Assessment Status
   * @type {Array<'finished' | 'canceled'>}
   * @memberof AssessmentApiGetAssessments
   */
  readonly status?: Array<"finished" | "canceled">;
}

/**
 * Request parameters for getPAssessmentDislikes operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetPAssessmentDislikesRequest
 */
export interface AssessmentApiGetPAssessmentDislikesRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof AssessmentApiGetPAssessmentDislikes
   */
  readonly playerId: string;

  /**
   * Assessment ID
   * @type {string}
   * @memberof AssessmentApiGetPAssessmentDislikes
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for getPAssessments operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetPAssessmentsRequest
 */
export interface AssessmentApiGetPAssessmentsRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly playerId: string;

  /**
   * Assessment Title
   * @type {Array<string>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly aTitle?: Array<string>;

  /**
   * Assessment Type
   * @type {Array<'rating' | 'nonRating'>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly aType?: Array<"rating" | "nonRating">;

  /**
   * Game Title
   * @type {Array<string>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly gTitle?: Array<string>;

  /**
   * Game Types
   * @type {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly gType?: Array<
    "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
  >;

  /**
   * Code
   * @type {Array<string>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly code?: Array<string>;

  /**
   * Current Players Count From
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly curPlayersFrom?: number;

  /**
   * Current Players Count To
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly curPlayersTo?: number;

  /**
   * Maximum Players Count From
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly maxPlayersFrom?: number;

  /**
   * Maximum Players Count To
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly maxPlayersTo?: number;

  /**
   * Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly createdFrom?: string;

  /**
   * Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly createdTo?: string;

  /**
   * Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly startedFrom?: string;

  /**
   * Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly startedTo?: string;

  /**
   * Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly finishedFrom?: string;

  /**
   * Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly finishedTo?: string;

  /**
   * Player Role in assessment
   * @type {Array<string>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly role?: Array<string>;

  /**
   * Player Place in assessment
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly placeFrom?: number;

  /**
   * Player Place in assessment
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly placeTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly analyticsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly analyticsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly analyticsTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly analyticsTenacityDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly communicationDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly communicationDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly communicationTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly communicationTenacityDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly commandDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly commandDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly commandTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly commandTenacityDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly thinking3dDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly thinking3dDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly thinking3dTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly thinking3dTenacityDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly economicsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly economicsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly economicsTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly economicsTenacityDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly jurisprudenceDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly jurisprudenceDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly jurisprudenceTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly jurisprudenceTenacityDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly microelectronicsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly microelectronicsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly microelectronicsTenacityDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly microelectronicsTenacityDiffTo?: number;

  /**
   *
   * @type {Array<string>}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly tenacityCompetenceNullQuery?: Array<string>;

  /**
   * sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly orderBy?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof AssessmentApiGetPAssessments
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for getPRoles operation in AssessmentApi.
 * @export
 * @interface AssessmentApiGetPRolesRequest
 */
export interface AssessmentApiGetPRolesRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof AssessmentApiGetPRoles
   */
  readonly playerId: string;
}

/**
 * Request parameters for postAssessment operation in AssessmentApi.
 * @export
 * @interface AssessmentApiPostAssessmentRequest
 */
export interface AssessmentApiPostAssessmentRequest {
  /**
   * Post Assessment with players/results/calcs
   * @type {AssessmentDetailDto}
   * @memberof AssessmentApiPostAssessment
   */
  readonly assessment: AssessmentDetailDto;
}

/**
 * Request parameters for postAssessmentDislikesBulkUpd operation in AssessmentApi.
 * @export
 * @interface AssessmentApiPostAssessmentDislikesBulkUpdRequest
 */
export interface AssessmentApiPostAssessmentDislikesBulkUpdRequest {
  /**
   * Assessment ID
   * @type {string}
   * @memberof AssessmentApiPostAssessmentDislikesBulkUpd
   */
  readonly assessmentId: string;

  /**
   *
   * @type {DislikesBulkUpd}
   * @memberof AssessmentApiPostAssessmentDislikesBulkUpd
   */
  readonly dislikes: DislikesBulkUpd;
}

/**
 * AssessmentApi - object-oriented interface
 * @export
 * @class AssessmentApi
 * @extends {BaseAPI}
 */
export class AssessmentApi extends BaseAPI {
  /**
   *
   * @summary Get an Assessment
   * @param {AssessmentApiGetAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getAssessment(
    requestParameters: AssessmentApiGetAssessmentRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getAssessment(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get All Assessments
   * @param {AssessmentApiGetAssessmentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getAssessments(
    requestParameters: AssessmentApiGetAssessmentsRequest = {},
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getAssessments(
        requestParameters.pId,
        requestParameters.gTitle,
        requestParameters.gType,
        requestParameters.aTitle,
        requestParameters.code,
        requestParameters.aType,
        requestParameters.curPlayersFrom,
        requestParameters.curPlayersTo,
        requestParameters.maxPlayersFrom,
        requestParameters.maxPlayersTo,
        requestParameters.firstName,
        requestParameters.lastName,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.startedFrom,
        requestParameters.startedTo,
        requestParameters.finishedFrom,
        requestParameters.finishedTo,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Assessment Dislikes
   * @param {AssessmentApiGetPAssessmentDislikesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getPAssessmentDislikes(
    requestParameters: AssessmentApiGetPAssessmentDislikesRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getPAssessmentDislikes(
        requestParameters.playerId,
        requestParameters.assessmentId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Assessment history
   * @param {AssessmentApiGetPAssessmentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getPAssessments(
    requestParameters: AssessmentApiGetPAssessmentsRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getPAssessments(
        requestParameters.playerId,
        requestParameters.aTitle,
        requestParameters.aType,
        requestParameters.gTitle,
        requestParameters.gType,
        requestParameters.code,
        requestParameters.curPlayersFrom,
        requestParameters.curPlayersTo,
        requestParameters.maxPlayersFrom,
        requestParameters.maxPlayersTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.startedFrom,
        requestParameters.startedTo,
        requestParameters.finishedFrom,
        requestParameters.finishedTo,
        requestParameters.role,
        requestParameters.placeFrom,
        requestParameters.placeTo,
        requestParameters.analyticsDiffFrom,
        requestParameters.analyticsDiffTo,
        requestParameters.analyticsTenacityDiffFrom,
        requestParameters.analyticsTenacityDiffTo,
        requestParameters.communicationDiffFrom,
        requestParameters.communicationDiffTo,
        requestParameters.communicationTenacityDiffFrom,
        requestParameters.communicationTenacityDiffTo,
        requestParameters.commandDiffFrom,
        requestParameters.commandDiffTo,
        requestParameters.commandTenacityDiffFrom,
        requestParameters.commandTenacityDiffTo,
        requestParameters.thinking3dDiffFrom,
        requestParameters.thinking3dDiffTo,
        requestParameters.thinking3dTenacityDiffFrom,
        requestParameters.thinking3dTenacityDiffTo,
        requestParameters.economicsDiffFrom,
        requestParameters.economicsDiffTo,
        requestParameters.economicsTenacityDiffFrom,
        requestParameters.economicsTenacityDiffTo,
        requestParameters.jurisprudenceDiffFrom,
        requestParameters.jurisprudenceDiffTo,
        requestParameters.jurisprudenceTenacityDiffFrom,
        requestParameters.jurisprudenceTenacityDiffTo,
        requestParameters.microelectronicsDiffFrom,
        requestParameters.microelectronicsDiffTo,
        requestParameters.microelectronicsTenacityDiffFrom,
        requestParameters.microelectronicsTenacityDiffTo,
        requestParameters.tenacityCompetenceNullQuery,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Player Assessment history roles list
   * @param {AssessmentApiGetPRolesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public getPRoles(
    requestParameters: AssessmentApiGetPRolesRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .getPRoles(requestParameters.playerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add an Assessment with players/results/calcs
   * @param {AssessmentApiPostAssessmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public postAssessment(
    requestParameters: AssessmentApiPostAssessmentRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .postAssessment(requestParameters.assessment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add an Assessment dislikes
   * @param {AssessmentApiPostAssessmentDislikesBulkUpdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssessmentApi
   */
  public postAssessmentDislikesBulkUpd(
    requestParameters: AssessmentApiPostAssessmentDislikesBulkUpdRequest,
    options?: any,
  ) {
    return AssessmentApiFp(this.configuration)
      .postAssessmentDislikesBulkUpd(
        requestParameters.assessmentId,
        requestParameters.dislikes,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Export Assessment Story To Csv
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentStoryToCsv: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "exportAssessmentStoryToCsv",
        "assessmentId",
        assessmentId,
      );
      const localVarPath =
        `/api/v1/assessments/{assessment_id}/exportAssessmentStoryToCsv`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Assessment Story To Xlsx
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentStoryToXlsx: async (
      assessmentId: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assessmentId' is not null or undefined
      assertParamExists(
        "exportAssessmentStoryToXlsx",
        "assessmentId",
        assessmentId,
      );
      const localVarPath =
        `/api/v1/assessments/{assessment_id}/exportAssessmentStoryToXlsx`.replace(
          `{${"assessment_id"}}`,
          encodeURIComponent(String(assessmentId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Assessments Story To Csv
     * @param {Array<string>} [aId] Assessment IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentsStoryToCsv: async (
      aId?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/assessments/exportsAssessmentStoryToCsv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (aId) {
        localVarQueryParameter["aId"] = aId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Assessments Story To Xlsx
     * @param {Array<string>} [aId] Assessment IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentsStoryToXlsx: async (
      aId?: Array<string>,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/assessments/exportsAssessmentStoryToXlsx`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (aId) {
        localVarQueryParameter["aId"] = aId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export User Assessments Story To Csv
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportUserStoryToCsv: async (
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("exportUserStoryToCsv", "playerId", playerId);
      const localVarPath =
        `/api/v1/players/{player_id}/exportUserStoryToCsv`.replace(
          `{${"player_id"}}`,
          encodeURIComponent(String(playerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (aTitle) {
        localVarQueryParameter["aTitle"] = aTitle;
      }

      if (aType) {
        localVarQueryParameter["aType"] = aType;
      }

      if (gTitle) {
        localVarQueryParameter["gTitle"] = gTitle;
      }

      if (gType) {
        localVarQueryParameter["gType"] = gType;
      }

      if (code) {
        localVarQueryParameter["code"] = code;
      }

      if (curPlayersFrom !== undefined) {
        localVarQueryParameter["curPlayersFrom"] = curPlayersFrom;
      }

      if (curPlayersTo !== undefined) {
        localVarQueryParameter["curPlayersTo"] = curPlayersTo;
      }

      if (maxPlayersFrom !== undefined) {
        localVarQueryParameter["maxPlayersFrom"] = maxPlayersFrom;
      }

      if (maxPlayersTo !== undefined) {
        localVarQueryParameter["maxPlayersTo"] = maxPlayersTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] =
          (createdFrom as any) instanceof Date
            ? (createdFrom as any).toISOString()
            : createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] =
          (createdTo as any) instanceof Date
            ? (createdTo as any).toISOString()
            : createdTo;
      }

      if (startedFrom !== undefined) {
        localVarQueryParameter["startedFrom"] =
          (startedFrom as any) instanceof Date
            ? (startedFrom as any).toISOString()
            : startedFrom;
      }

      if (startedTo !== undefined) {
        localVarQueryParameter["startedTo"] =
          (startedTo as any) instanceof Date
            ? (startedTo as any).toISOString()
            : startedTo;
      }

      if (finishedFrom !== undefined) {
        localVarQueryParameter["finishedFrom"] =
          (finishedFrom as any) instanceof Date
            ? (finishedFrom as any).toISOString()
            : finishedFrom;
      }

      if (finishedTo !== undefined) {
        localVarQueryParameter["finishedTo"] =
          (finishedTo as any) instanceof Date
            ? (finishedTo as any).toISOString()
            : finishedTo;
      }

      if (role) {
        localVarQueryParameter["role"] = role;
      }

      if (placeFrom !== undefined) {
        localVarQueryParameter["placeFrom"] = placeFrom;
      }

      if (placeTo !== undefined) {
        localVarQueryParameter["placeTo"] = placeTo;
      }

      if (analyticsDiffFrom !== undefined) {
        localVarQueryParameter["analyticsDiffFrom"] = analyticsDiffFrom;
      }

      if (analyticsDiffTo !== undefined) {
        localVarQueryParameter["analyticsDiffTo"] = analyticsDiffTo;
      }

      if (communicationDiffFrom !== undefined) {
        localVarQueryParameter["communicationDiffFrom"] = communicationDiffFrom;
      }

      if (communicationDiffTo !== undefined) {
        localVarQueryParameter["communicationDiffTo"] = communicationDiffTo;
      }

      if (commandDiffFrom !== undefined) {
        localVarQueryParameter["commandDiffFrom"] = commandDiffFrom;
      }

      if (commandDiffTo !== undefined) {
        localVarQueryParameter["commandDiffTo"] = commandDiffTo;
      }

      if (thinking3dDiffFrom !== undefined) {
        localVarQueryParameter["thinking3dDiffFrom"] = thinking3dDiffFrom;
      }

      if (thinking3dDiffTo !== undefined) {
        localVarQueryParameter["thinking3dDiffTo"] = thinking3dDiffTo;
      }

      if (economicsDiffFrom !== undefined) {
        localVarQueryParameter["economicsDiffFrom"] = economicsDiffFrom;
      }

      if (economicsDiffTo !== undefined) {
        localVarQueryParameter["economicsDiffTo"] = economicsDiffTo;
      }

      if (jurisprudenceDiffFrom !== undefined) {
        localVarQueryParameter["jurisprudenceDiffFrom"] = jurisprudenceDiffFrom;
      }

      if (jurisprudenceDiffTo !== undefined) {
        localVarQueryParameter["jurisprudenceDiffTo"] = jurisprudenceDiffTo;
      }

      if (microelectronicsDiffFrom !== undefined) {
        localVarQueryParameter["microelectronicsDiffFrom"] =
          microelectronicsDiffFrom;
      }

      if (microelectronicsDiffTo !== undefined) {
        localVarQueryParameter["microelectronicsDiffTo"] =
          microelectronicsDiffTo;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export User Assessments Story To Xlsx
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportUserStoryToXlsx: async (
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'playerId' is not null or undefined
      assertParamExists("exportUserStoryToXlsx", "playerId", playerId);
      const localVarPath =
        `/api/v1/players/{player_id}/exportUserStoryToXlsx`.replace(
          `{${"player_id"}}`,
          encodeURIComponent(String(playerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer_key required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration,
      );

      // authentication kc_dev_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_dev_auth",
        [],
        configuration,
      );

      // authentication kc_local_auth required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        "kc_local_auth",
        [],
        configuration,
      );

      if (aTitle) {
        localVarQueryParameter["aTitle"] = aTitle;
      }

      if (aType) {
        localVarQueryParameter["aType"] = aType;
      }

      if (gTitle) {
        localVarQueryParameter["gTitle"] = gTitle;
      }

      if (gType) {
        localVarQueryParameter["gType"] = gType;
      }

      if (code) {
        localVarQueryParameter["code"] = code;
      }

      if (curPlayersFrom !== undefined) {
        localVarQueryParameter["curPlayersFrom"] = curPlayersFrom;
      }

      if (curPlayersTo !== undefined) {
        localVarQueryParameter["curPlayersTo"] = curPlayersTo;
      }

      if (maxPlayersFrom !== undefined) {
        localVarQueryParameter["maxPlayersFrom"] = maxPlayersFrom;
      }

      if (maxPlayersTo !== undefined) {
        localVarQueryParameter["maxPlayersTo"] = maxPlayersTo;
      }

      if (createdFrom !== undefined) {
        localVarQueryParameter["createdFrom"] =
          (createdFrom as any) instanceof Date
            ? (createdFrom as any).toISOString()
            : createdFrom;
      }

      if (createdTo !== undefined) {
        localVarQueryParameter["createdTo"] =
          (createdTo as any) instanceof Date
            ? (createdTo as any).toISOString()
            : createdTo;
      }

      if (startedFrom !== undefined) {
        localVarQueryParameter["startedFrom"] =
          (startedFrom as any) instanceof Date
            ? (startedFrom as any).toISOString()
            : startedFrom;
      }

      if (startedTo !== undefined) {
        localVarQueryParameter["startedTo"] =
          (startedTo as any) instanceof Date
            ? (startedTo as any).toISOString()
            : startedTo;
      }

      if (finishedFrom !== undefined) {
        localVarQueryParameter["finishedFrom"] =
          (finishedFrom as any) instanceof Date
            ? (finishedFrom as any).toISOString()
            : finishedFrom;
      }

      if (finishedTo !== undefined) {
        localVarQueryParameter["finishedTo"] =
          (finishedTo as any) instanceof Date
            ? (finishedTo as any).toISOString()
            : finishedTo;
      }

      if (role) {
        localVarQueryParameter["role"] = role;
      }

      if (placeFrom !== undefined) {
        localVarQueryParameter["placeFrom"] = placeFrom;
      }

      if (placeTo !== undefined) {
        localVarQueryParameter["placeTo"] = placeTo;
      }

      if (analyticsDiffFrom !== undefined) {
        localVarQueryParameter["analyticsDiffFrom"] = analyticsDiffFrom;
      }

      if (analyticsDiffTo !== undefined) {
        localVarQueryParameter["analyticsDiffTo"] = analyticsDiffTo;
      }

      if (communicationDiffFrom !== undefined) {
        localVarQueryParameter["communicationDiffFrom"] = communicationDiffFrom;
      }

      if (communicationDiffTo !== undefined) {
        localVarQueryParameter["communicationDiffTo"] = communicationDiffTo;
      }

      if (commandDiffFrom !== undefined) {
        localVarQueryParameter["commandDiffFrom"] = commandDiffFrom;
      }

      if (commandDiffTo !== undefined) {
        localVarQueryParameter["commandDiffTo"] = commandDiffTo;
      }

      if (thinking3dDiffFrom !== undefined) {
        localVarQueryParameter["thinking3dDiffFrom"] = thinking3dDiffFrom;
      }

      if (thinking3dDiffTo !== undefined) {
        localVarQueryParameter["thinking3dDiffTo"] = thinking3dDiffTo;
      }

      if (economicsDiffFrom !== undefined) {
        localVarQueryParameter["economicsDiffFrom"] = economicsDiffFrom;
      }

      if (economicsDiffTo !== undefined) {
        localVarQueryParameter["economicsDiffTo"] = economicsDiffTo;
      }

      if (jurisprudenceDiffFrom !== undefined) {
        localVarQueryParameter["jurisprudenceDiffFrom"] = jurisprudenceDiffFrom;
      }

      if (jurisprudenceDiffTo !== undefined) {
        localVarQueryParameter["jurisprudenceDiffTo"] = jurisprudenceDiffTo;
      }

      if (microelectronicsDiffFrom !== undefined) {
        localVarQueryParameter["microelectronicsDiffFrom"] =
          microelectronicsDiffFrom;
      }

      if (microelectronicsDiffTo !== undefined) {
        localVarQueryParameter["microelectronicsDiffTo"] =
          microelectronicsDiffTo;
      }

      if (orderBy !== undefined) {
        localVarQueryParameter["orderBy"] = orderBy;
      }

      if (pageNum !== undefined) {
        localVarQueryParameter["pageNum"] = pageNum;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter["pageSize"] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export Assessment Story To Csv
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAssessmentStoryToCsv(
      assessmentId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAssessmentStoryToCsv(
          assessmentId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Export Assessment Story To Xlsx
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAssessmentStoryToXlsx(
      assessmentId: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAssessmentStoryToXlsx(
          assessmentId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Export Assessments Story To Csv
     * @param {Array<string>} [aId] Assessment IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAssessmentsStoryToCsv(
      aId?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAssessmentsStoryToCsv(
          aId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Export Assessments Story To Xlsx
     * @param {Array<string>} [aId] Assessment IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAssessmentsStoryToXlsx(
      aId?: Array<string>,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAssessmentsStoryToXlsx(
          aId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Export User Assessments Story To Csv
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportUserStoryToCsv(
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportUserStoryToCsv(
          playerId,
          aTitle,
          aType,
          gTitle,
          gType,
          code,
          curPlayersFrom,
          curPlayersTo,
          maxPlayersFrom,
          maxPlayersTo,
          createdFrom,
          createdTo,
          startedFrom,
          startedTo,
          finishedFrom,
          finishedTo,
          role,
          placeFrom,
          placeTo,
          analyticsDiffFrom,
          analyticsDiffTo,
          communicationDiffFrom,
          communicationDiffTo,
          commandDiffFrom,
          commandDiffTo,
          thinking3dDiffFrom,
          thinking3dDiffTo,
          economicsDiffFrom,
          economicsDiffTo,
          jurisprudenceDiffFrom,
          jurisprudenceDiffTo,
          microelectronicsDiffFrom,
          microelectronicsDiffTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Export User Assessments Story To Xlsx
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportUserStoryToXlsx(
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportUserStoryToXlsx(
          playerId,
          aTitle,
          aType,
          gTitle,
          gType,
          code,
          curPlayersFrom,
          curPlayersTo,
          maxPlayersFrom,
          maxPlayersTo,
          createdFrom,
          createdTo,
          startedFrom,
          startedTo,
          finishedFrom,
          finishedTo,
          role,
          placeFrom,
          placeTo,
          analyticsDiffFrom,
          analyticsDiffTo,
          communicationDiffFrom,
          communicationDiffTo,
          commandDiffFrom,
          commandDiffTo,
          thinking3dDiffFrom,
          thinking3dDiffTo,
          economicsDiffFrom,
          economicsDiffTo,
          jurisprudenceDiffFrom,
          jurisprudenceDiffTo,
          microelectronicsDiffFrom,
          microelectronicsDiffTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExportApiFp(configuration);
  return {
    /**
     *
     * @summary Export Assessment Story To Csv
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentStoryToCsv(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportAssessmentStoryToCsv(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export Assessment Story To Xlsx
     * @param {string} assessmentId Assessment ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentStoryToXlsx(
      assessmentId: string,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportAssessmentStoryToXlsx(assessmentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export Assessments Story To Csv
     * @param {Array<string>} [aId] Assessment IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentsStoryToCsv(
      aId?: Array<string>,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportAssessmentsStoryToCsv(aId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export Assessments Story To Xlsx
     * @param {Array<string>} [aId] Assessment IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAssessmentsStoryToXlsx(
      aId?: Array<string>,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportAssessmentsStoryToXlsx(aId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export User Assessments Story To Csv
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportUserStoryToCsv(
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportUserStoryToCsv(
          playerId,
          aTitle,
          aType,
          gTitle,
          gType,
          code,
          curPlayersFrom,
          curPlayersTo,
          maxPlayersFrom,
          maxPlayersTo,
          createdFrom,
          createdTo,
          startedFrom,
          startedTo,
          finishedFrom,
          finishedTo,
          role,
          placeFrom,
          placeTo,
          analyticsDiffFrom,
          analyticsDiffTo,
          communicationDiffFrom,
          communicationDiffTo,
          commandDiffFrom,
          commandDiffTo,
          thinking3dDiffFrom,
          thinking3dDiffTo,
          economicsDiffFrom,
          economicsDiffTo,
          jurisprudenceDiffFrom,
          jurisprudenceDiffTo,
          microelectronicsDiffFrom,
          microelectronicsDiffTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export User Assessments Story To Xlsx
     * @param {string} playerId Player ID
     * @param {Array<string>} [aTitle] Assessment Title
     * @param {Array<'rating' | 'nonRating'>} [aType] Assessment Type
     * @param {Array<string>} [gTitle] Game Title
     * @param {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>} [gType] Game Types
     * @param {Array<string>} [code] Code
     * @param {number} [curPlayersFrom] Current Players Count From
     * @param {number} [curPlayersTo] Current Players Count To
     * @param {number} [maxPlayersFrom] Maximum Players Count From
     * @param {number} [maxPlayersTo] Maximum Players Count To
     * @param {string} [createdFrom] Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [createdTo] Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedFrom] Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [startedTo] Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedFrom] Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {string} [finishedTo] Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
     * @param {Array<string>} [role] Player Role in assessment
     * @param {number} [placeFrom] Player Place in assessment
     * @param {number} [placeTo] Player Place in assessment
     * @param {number} [analyticsDiffFrom]
     * @param {number} [analyticsDiffTo]
     * @param {number} [communicationDiffFrom]
     * @param {number} [communicationDiffTo]
     * @param {number} [commandDiffFrom]
     * @param {number} [commandDiffTo]
     * @param {number} [thinking3dDiffFrom]
     * @param {number} [thinking3dDiffTo]
     * @param {number} [economicsDiffFrom]
     * @param {number} [economicsDiffTo]
     * @param {number} [jurisprudenceDiffFrom]
     * @param {number} [jurisprudenceDiffTo]
     * @param {number} [microelectronicsDiffFrom]
     * @param {number} [microelectronicsDiffTo]
     * @param {string} [orderBy] sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
     * @param {number} [pageNum] Page Number, one-based, default 1.
     * @param {number} [pageSize] Page Size, [1..1000] default 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportUserStoryToXlsx(
      playerId: string,
      aTitle?: Array<string>,
      aType?: Array<"rating" | "nonRating">,
      gTitle?: Array<string>,
      gType?: Array<
        "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
      >,
      code?: Array<string>,
      curPlayersFrom?: number,
      curPlayersTo?: number,
      maxPlayersFrom?: number,
      maxPlayersTo?: number,
      createdFrom?: string,
      createdTo?: string,
      startedFrom?: string,
      startedTo?: string,
      finishedFrom?: string,
      finishedTo?: string,
      role?: Array<string>,
      placeFrom?: number,
      placeTo?: number,
      analyticsDiffFrom?: number,
      analyticsDiffTo?: number,
      communicationDiffFrom?: number,
      communicationDiffTo?: number,
      commandDiffFrom?: number,
      commandDiffTo?: number,
      thinking3dDiffFrom?: number,
      thinking3dDiffTo?: number,
      economicsDiffFrom?: number,
      economicsDiffTo?: number,
      jurisprudenceDiffFrom?: number,
      jurisprudenceDiffTo?: number,
      microelectronicsDiffFrom?: number,
      microelectronicsDiffTo?: number,
      orderBy?: string,
      pageNum?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .exportUserStoryToXlsx(
          playerId,
          aTitle,
          aType,
          gTitle,
          gType,
          code,
          curPlayersFrom,
          curPlayersTo,
          maxPlayersFrom,
          maxPlayersTo,
          createdFrom,
          createdTo,
          startedFrom,
          startedTo,
          finishedFrom,
          finishedTo,
          role,
          placeFrom,
          placeTo,
          analyticsDiffFrom,
          analyticsDiffTo,
          communicationDiffFrom,
          communicationDiffTo,
          commandDiffFrom,
          commandDiffTo,
          thinking3dDiffFrom,
          thinking3dDiffTo,
          economicsDiffFrom,
          economicsDiffTo,
          jurisprudenceDiffFrom,
          jurisprudenceDiffTo,
          microelectronicsDiffFrom,
          microelectronicsDiffTo,
          orderBy,
          pageNum,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportAssessmentStoryToCsv operation in ExportApi.
 * @export
 * @interface ExportApiExportAssessmentStoryToCsvRequest
 */
export interface ExportApiExportAssessmentStoryToCsvRequest {
  /**
   * Assessment ID
   * @type {string}
   * @memberof ExportApiExportAssessmentStoryToCsv
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for exportAssessmentStoryToXlsx operation in ExportApi.
 * @export
 * @interface ExportApiExportAssessmentStoryToXlsxRequest
 */
export interface ExportApiExportAssessmentStoryToXlsxRequest {
  /**
   * Assessment ID
   * @type {string}
   * @memberof ExportApiExportAssessmentStoryToXlsx
   */
  readonly assessmentId: string;
}

/**
 * Request parameters for exportAssessmentsStoryToCsv operation in ExportApi.
 * @export
 * @interface ExportApiExportAssessmentsStoryToCsvRequest
 */
export interface ExportApiExportAssessmentsStoryToCsvRequest {
  /**
   * Assessment IDs
   * @type {Array<string>}
   * @memberof ExportApiExportAssessmentsStoryToCsv
   */
  readonly aId?: Array<string>;
}

/**
 * Request parameters for exportAssessmentsStoryToXlsx operation in ExportApi.
 * @export
 * @interface ExportApiExportAssessmentsStoryToXlsxRequest
 */
export interface ExportApiExportAssessmentsStoryToXlsxRequest {
  /**
   * Assessment IDs
   * @type {Array<string>}
   * @memberof ExportApiExportAssessmentsStoryToXlsx
   */
  readonly aId?: Array<string>;
}

/**
 * Request parameters for exportUserStoryToCsv operation in ExportApi.
 * @export
 * @interface ExportApiExportUserStoryToCsvRequest
 */
export interface ExportApiExportUserStoryToCsvRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly playerId: string;

  /**
   * Assessment Title
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly aTitle?: Array<string>;

  /**
   * Assessment Type
   * @type {Array<'rating' | 'nonRating'>}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly aType?: Array<"rating" | "nonRating">;

  /**
   * Game Title
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly gTitle?: Array<string>;

  /**
   * Game Types
   * @type {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly gType?: Array<
    "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
  >;

  /**
   * Code
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly code?: Array<string>;

  /**
   * Current Players Count From
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly curPlayersFrom?: number;

  /**
   * Current Players Count To
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly curPlayersTo?: number;

  /**
   * Maximum Players Count From
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly maxPlayersFrom?: number;

  /**
   * Maximum Players Count To
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly maxPlayersTo?: number;

  /**
   * Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly createdFrom?: string;

  /**
   * Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly createdTo?: string;

  /**
   * Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly startedFrom?: string;

  /**
   * Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly startedTo?: string;

  /**
   * Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly finishedFrom?: string;

  /**
   * Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly finishedTo?: string;

  /**
   * Player Role in assessment
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly role?: Array<string>;

  /**
   * Player Place in assessment
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly placeFrom?: number;

  /**
   * Player Place in assessment
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly placeTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly analyticsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly analyticsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly communicationDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly communicationDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly commandDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly commandDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly thinking3dDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly thinking3dDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly economicsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly economicsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly jurisprudenceDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly jurisprudenceDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly microelectronicsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly microelectronicsDiffTo?: number;

  /**
   * sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly orderBy?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof ExportApiExportUserStoryToCsv
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for exportUserStoryToXlsx operation in ExportApi.
 * @export
 * @interface ExportApiExportUserStoryToXlsxRequest
 */
export interface ExportApiExportUserStoryToXlsxRequest {
  /**
   * Player ID
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly playerId: string;

  /**
   * Assessment Title
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly aTitle?: Array<string>;

  /**
   * Assessment Type
   * @type {Array<'rating' | 'nonRating'>}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly aType?: Array<"rating" | "nonRating">;

  /**
   * Game Title
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly gTitle?: Array<string>;

  /**
   * Game Types
   * @type {Array<'tutorial' | 'tutorial_ltd' | 'single' | 'solo' | 'team' | 'corp'>}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly gType?: Array<
    "tutorial" | "tutorial_ltd" | "single" | "solo" | "team" | "corp"
  >;

  /**
   * Code
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly code?: Array<string>;

  /**
   * Current Players Count From
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly curPlayersFrom?: number;

  /**
   * Current Players Count To
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly curPlayersTo?: number;

  /**
   * Maximum Players Count From
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly maxPlayersFrom?: number;

  /**
   * Maximum Players Count To
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly maxPlayersTo?: number;

  /**
   * Created At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly createdFrom?: string;

  /**
   * Created At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly createdTo?: string;

  /**
   * Started At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly startedFrom?: string;

  /**
   * Started At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly startedTo?: string;

  /**
   * Finished At From Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly finishedFrom?: string;

  /**
   * Finished At To Timestamp. &lt;a href&#x3D;\&quot;https://datatracker.ietf.org/doc/html/rfc3339#section-5.6\&quot;&gt;   RFC 3339 -- Date and Time on the Internet: Timestamps &lt;/a&gt;.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly finishedTo?: string;

  /**
   * Player Role in assessment
   * @type {Array<string>}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly role?: Array<string>;

  /**
   * Player Place in assessment
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly placeFrom?: number;

  /**
   * Player Place in assessment
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly placeTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly analyticsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly analyticsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly communicationDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly communicationDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly commandDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly commandDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly thinking3dDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly thinking3dDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly economicsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly economicsDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly jurisprudenceDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly jurisprudenceDiffTo?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly microelectronicsDiffFrom?: number;

  /**
   *
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly microelectronicsDiffTo?: number;

  /**
   * sort assessments by fields &#x60;gTitle&#x60;/&#x60;code&#x60;/&#x60;aType&#x60;/&#x60;curPlayers&#x60;/&#x60;maxPlayers&#x60;/ &#x60;created&#x60;/&#x60;started&#x60;/&#x60;finished&#x60;/ &#x60;role&#x60;/&#x60;place&#x60;/&#x60;analyticsDiff&#x60;/&#x60;communicationDiff&#x60;/&#x60;commandDiff&#x60;, with \&#39;+\&#39; or none prefix to ASC, with \&#39;-\&#39; prefix to DESC.
   * @type {string}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly orderBy?: string;

  /**
   * Page Number, one-based, default 1.
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly pageNum?: number;

  /**
   * Page Size, [1..1000] default 10.
   * @type {number}
   * @memberof ExportApiExportUserStoryToXlsx
   */
  readonly pageSize?: number;
}

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
  /**
   *
   * @summary Export Assessment Story To Csv
   * @param {ExportApiExportAssessmentStoryToCsvRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportAssessmentStoryToCsv(
    requestParameters: ExportApiExportAssessmentStoryToCsvRequest,
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .exportAssessmentStoryToCsv(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Assessment Story To Xlsx
   * @param {ExportApiExportAssessmentStoryToXlsxRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportAssessmentStoryToXlsx(
    requestParameters: ExportApiExportAssessmentStoryToXlsxRequest,
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .exportAssessmentStoryToXlsx(requestParameters.assessmentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Assessments Story To Csv
   * @param {ExportApiExportAssessmentsStoryToCsvRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportAssessmentsStoryToCsv(
    requestParameters: ExportApiExportAssessmentsStoryToCsvRequest = {},
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .exportAssessmentsStoryToCsv(requestParameters.aId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Assessments Story To Xlsx
   * @param {ExportApiExportAssessmentsStoryToXlsxRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportAssessmentsStoryToXlsx(
    requestParameters: ExportApiExportAssessmentsStoryToXlsxRequest = {},
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .exportAssessmentsStoryToXlsx(requestParameters.aId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export User Assessments Story To Csv
   * @param {ExportApiExportUserStoryToCsvRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportUserStoryToCsv(
    requestParameters: ExportApiExportUserStoryToCsvRequest,
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .exportUserStoryToCsv(
        requestParameters.playerId,
        requestParameters.aTitle,
        requestParameters.aType,
        requestParameters.gTitle,
        requestParameters.gType,
        requestParameters.code,
        requestParameters.curPlayersFrom,
        requestParameters.curPlayersTo,
        requestParameters.maxPlayersFrom,
        requestParameters.maxPlayersTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.startedFrom,
        requestParameters.startedTo,
        requestParameters.finishedFrom,
        requestParameters.finishedTo,
        requestParameters.role,
        requestParameters.placeFrom,
        requestParameters.placeTo,
        requestParameters.analyticsDiffFrom,
        requestParameters.analyticsDiffTo,
        requestParameters.communicationDiffFrom,
        requestParameters.communicationDiffTo,
        requestParameters.commandDiffFrom,
        requestParameters.commandDiffTo,
        requestParameters.thinking3dDiffFrom,
        requestParameters.thinking3dDiffTo,
        requestParameters.economicsDiffFrom,
        requestParameters.economicsDiffTo,
        requestParameters.jurisprudenceDiffFrom,
        requestParameters.jurisprudenceDiffTo,
        requestParameters.microelectronicsDiffFrom,
        requestParameters.microelectronicsDiffTo,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export User Assessments Story To Xlsx
   * @param {ExportApiExportUserStoryToXlsxRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportUserStoryToXlsx(
    requestParameters: ExportApiExportUserStoryToXlsxRequest,
    options?: any,
  ) {
    return ExportApiFp(this.configuration)
      .exportUserStoryToXlsx(
        requestParameters.playerId,
        requestParameters.aTitle,
        requestParameters.aType,
        requestParameters.gTitle,
        requestParameters.gType,
        requestParameters.code,
        requestParameters.curPlayersFrom,
        requestParameters.curPlayersTo,
        requestParameters.maxPlayersFrom,
        requestParameters.maxPlayersTo,
        requestParameters.createdFrom,
        requestParameters.createdTo,
        requestParameters.startedFrom,
        requestParameters.startedTo,
        requestParameters.finishedFrom,
        requestParameters.finishedTo,
        requestParameters.role,
        requestParameters.placeFrom,
        requestParameters.placeTo,
        requestParameters.analyticsDiffFrom,
        requestParameters.analyticsDiffTo,
        requestParameters.communicationDiffFrom,
        requestParameters.communicationDiffTo,
        requestParameters.commandDiffFrom,
        requestParameters.commandDiffTo,
        requestParameters.thinking3dDiffFrom,
        requestParameters.thinking3dDiffTo,
        requestParameters.economicsDiffFrom,
        requestParameters.economicsDiffTo,
        requestParameters.jurisprudenceDiffFrom,
        requestParameters.jurisprudenceDiffTo,
        requestParameters.microelectronicsDiffFrom,
        requestParameters.microelectronicsDiffTo,
        requestParameters.orderBy,
        requestParameters.pageNum,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
