import { permissionsDomain } from "./domain";
import { PermissionDto } from "src/generated/social";
import {
  GetUserPermissions,
  WriteUserPermissions,
} from "../../../../shared/api/public/permissions/model/query";
import {
  getAllUserPermissions,
  writeAllUserPermissions,
} from "../../../../shared/api/public/permissions";
import { sample } from "effector";

export type FlatPermission = Omit<PermissionDto, "children"> & {
  children?: PermissionDto[];
};

const flatten = (permissions: PermissionDto[]): FlatPermission[] =>
  permissions.reduce((prev, current) => {
    const { children, ...rest } = current;

    return [...prev, rest, ...flatten(children)];
  }, [] as FlatPermission[]);

const setDefaults = (permissions: PermissionDto[]): PermissionDto[] =>
  permissions.map((permission) => ({
    ...permission,
    is_enabled:
      permission.type === "checkbox" && !permission.is_enabled
        ? permission.is_default ?? false
        : permission.is_enabled ?? false,
    selected:
      permission.type === "group_radio" &&
      !permissions.some(
        (perm) => perm.group === permission.group && perm.selected,
      )
        ? permission.is_default ?? false
        : permission.selected ?? false,
    children: setDefaults(permission.children),
  }));

export const $permissions = permissionsDomain.createStore<PermissionDto[]>([]);

export const $myPermissions = permissionsDomain.createStore<FlatPermission[]>(
  [],
);

export const $isLoadingPermission =
  permissionsDomain.createStore<boolean>(true);

export const fetchUserPermissions =
  permissionsDomain.createEvent<GetUserPermissions>();

export const postAllUserPermissions =
  permissionsDomain.createEvent<WriteUserPermissions>();

export const fetchMyPermissions =
  permissionsDomain.createEvent<GetUserPermissions>();

export const fetchUserPermissionsFx = permissionsDomain.createEffect(
  getAllUserPermissions,
);

export const writeAllUserPermissionsFx = permissionsDomain.createEffect(
  writeAllUserPermissions,
);

export const fetchMyPermissionsFx = permissionsDomain.createEffect(
  getAllUserPermissions,
);

sample({
  clock: fetchMyPermissions,
  target: fetchMyPermissionsFx,
});

sample({
  clock: fetchMyPermissionsFx.doneData,
  fn: (response) => flatten(response.permissions),
  target: $myPermissions,
});

sample({
  clock: fetchUserPermissions,
  target: fetchUserPermissionsFx,
});

sample({
  clock: postAllUserPermissions,
  fn: (payload) => {
    payload.body.permissions = setDefaults(payload.body.permissions);

    return payload;
  },
  target: writeAllUserPermissionsFx,
});

sample({
  clock: fetchUserPermissionsFx.doneData,
  fn: ({ permissions }) => {
    return permissions || [];
  },
  target: $permissions,
});

sample({
  clock: fetchUserPermissionsFx.pending,
  target: $isLoadingPermission,
});
