import {
  Manager,
  ManagerApiDelManagerRequest,
  ManagerApiGetManagerRequest,
  ManagerApiGetManagersRequest,
  ManagerApiPostManagerRequest,
  ManagerApiPutManagerRequest,
} from "../../../../generated/social";
import {
  EContactsActions,
  ICleanContact,
  ICleanContacts,
  IFailureContactCreate,
  IFailureContactDelete,
  IFailureContactGet,
  IFailureContactsGet,
  IFailureContactUpdate,
  IRequestContactCreate,
  IRequestContactDelete,
  IRequestContactGet,
  IRequestContactsGet,
  IRequestContactUpdate,
  ISuccessContactCreate,
  ISuccessContactDelete,
  ISuccessContactGet,
  ISuccessContactsGet,
  ISuccessContactUpdate,
} from "./actionTypes";

export const cleanContacts = (): ICleanContacts => ({
  type: EContactsActions.CLEAN_CONTACTS,
});

export const requestContactsGet = (payload?: {
  options?: ManagerApiGetManagersRequest;
  callback?: () => void;
}): IRequestContactsGet => ({
  type: EContactsActions.REQUEST_CONTACTS_GET,
  payload,
});

export const successContactsGet = (
  payload: Manager[],
): ISuccessContactsGet => ({
  type: EContactsActions.SUCCESS_CONTACTS_GET,
  payload,
});

export const failureContactsGet = (): IFailureContactsGet => ({
  type: EContactsActions.FAILURE_CONTACTS_GET,
});

export const cleanContact = (): ICleanContact => ({
  type: EContactsActions.CLEAN_CONTACT,
});

export const requestContactGet = (payload: {
  options: ManagerApiGetManagerRequest;
  callback?: () => void;
}): IRequestContactGet => ({
  type: EContactsActions.REQUEST_CONTACT_GET,
  payload,
});

export const successContactGet = (payload: Manager): ISuccessContactGet => ({
  type: EContactsActions.SUCCESS_CONTACT_GET,
  payload,
});

export const failureContactGet = (): IFailureContactGet => ({
  type: EContactsActions.FAILURE_CONTACT_GET,
});

export const requestContactUpdate = (payload: {
  options: ManagerApiPutManagerRequest;
  callback?: () => void;
}): IRequestContactUpdate => ({
  type: EContactsActions.REQUEST_CONTACT_UPDATE,
  payload,
});

export const successContactUpdate = (): ISuccessContactUpdate => ({
  type: EContactsActions.SUCCESS_CONTACT_UPDATE,
});

export const failureContactUpdate = (): IFailureContactUpdate => ({
  type: EContactsActions.FAILURE_CONTACT_UPDATE,
});

export const requestContactDelete = (payload: {
  options: ManagerApiDelManagerRequest;
  callback?: () => void;
}): IRequestContactDelete => ({
  type: EContactsActions.REQUEST_CONTACT_DELETE,
  payload,
});

export const successContactDelete = (): ISuccessContactDelete => ({
  type: EContactsActions.SUCCESS_CONTACT_DELETE,
});

export const failureContactDelete = (): IFailureContactDelete => ({
  type: EContactsActions.FAILURE_CONTACT_DELETE,
});

export const requestContactCreate = (payload: {
  options: ManagerApiPostManagerRequest;
  callback?: () => void;
}): IRequestContactCreate => ({
  type: EContactsActions.REQUEST_CONTACT_CREATE,
  payload,
});

export const successContactCreate = (): ISuccessContactCreate => ({
  type: EContactsActions.SUCCESS_CONTACT_CREATE,
});

export const failureContactCreate = (): IFailureContactCreate => ({
  type: EContactsActions.FAILURE_CONTACT_CREATE,
});

export type TContactsActions =
  | ICleanContacts
  | IRequestContactsGet
  | ISuccessContactsGet
  | IFailureContactsGet
  | ICleanContact
  | IRequestContactGet
  | ISuccessContactGet
  | IFailureContactGet
  | IRequestContactUpdate
  | ISuccessContactUpdate
  | IFailureContactUpdate
  | IRequestContactDelete
  | ISuccessContactDelete
  | IFailureContactDelete
  | IRequestContactCreate
  | ISuccessContactCreate
  | IFailureContactCreate;
