import { Competence, PlayerCompetenceRate } from "../../../../generated/social";

//(analytics command communication rate) / 2
export const getCompetencyForPlayer = (
  playerCompetenceRate?: PlayerCompetenceRate[],
): number => {
  if (!playerCompetenceRate || playerCompetenceRate.length === 0) {
    return 0;
  }

  const relevantCompetences = playerCompetenceRate.filter(({ competence }) =>
    [Competence.Analytics, Competence.Communication].includes(competence),
  );

  if (relevantCompetences.length === 0) {
    return 0;
  }

  const totalRate = relevantCompetences.reduce(
    (sum, { rate }) => sum + rate,
    0,
  );
  const averageRate = totalRate / relevantCompetences.length;

  return Math.round(averageRate * 10) / 10;
};
