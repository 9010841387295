import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import "./stat-block.scss";

interface IStatItem {
  title: string;
  description: string;
}

interface Props {
  isPlayer?: boolean;
  avatarUrl: string;
  gRate?: string;
  gPlace?: number;
  communication?: string;
  analytics?: string;
  command?: string;
}

export const StatBlock: FC<Props> = ({
  isPlayer,
  avatarUrl,
  gRate,
  gPlace,
  communication,
  analytics,
  command,
}): ReactElement => {
  const { t } = useTranslation();

  const statItems: IStatItem[] = [];

  if (isPlayer) {
    statItems.push(
      {
        title: t("profile.competence.title.general"),
        description: gRate || "-",
      },
      {
        title: t("profile.competence.title.placeInTable"),
        description: String(gPlace || "-"),
      },
      {
        title: t("profile.competence.title.communication"),
        description: communication || "-",
      },
      {
        title: t("profile.competence.title.analytics"),
        description: analytics || "-",
      },
      {
        title: t("profile.competence.title.command"),
        description: command || "-",
      },
    );
  }

  return (
    <div className="stat-block__container">
      <div
        className="stat-block__img"
        style={{
          backgroundImage: `url(${avatarUrl})`,
        }}
      />
      {!!isPlayer && (
        <ul className="stat-block__list">
          {statItems.map((item) => (
            <li key={item.title} className="stat-block__list-item">
              <span className="stat-block__list-item-title">{item.title}</span>
              <span className="stat-block__list-item-description">
                {item.description}
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
