import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUnit } from "effector-react";
import { useUrlState } from "src/shared/hooks/useUrlState";
import {
  BaseButton,
  BaseInputNumber,
  BaseInputText,
  BasePopup,
  ConfirmPopup,
  EPopupName,
  InputCheckbox,
} from "../../../shared/components";
import {
  $assessmentsListAdmin,
  setAssessmentsListAdminIsReadmore,
  cleanAssessmentsListAdmin,
  requestAssessmentsListAdmin,
  IRequestAssessmentsListAdmin,
} from "src/entities/public/assessments-list-admin";
import { ERequestStatus } from "../../../shared/store/types";
import { PageWrapper } from "src/shared/components";
import { AssessmentStatus, AssessmentType } from "../../../generated/game";
import { TDataGridDataParams } from "../../../shared/models/dataGrid";
import isEqual from "lodash/isEqual";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import "./planed-page.scss";
import { PlanedAssessmentsTable } from "../../../shared/components/data-grids/planed-page/planed-assessments-table";
import { openAddAssessmentPopup } from "../../../features/hr/assessments/lib/openPopups";
import { assessmentsModel } from "src/entities/public/assessments";

import { simulationsModel } from "src/entities/admin/simulations";
import { exportAssessments } from "src/entities/public/assessments/model";
import {
  $activePopups,
  closePopup,
  openPopup,
} from "src/shared/components/base-popup/model";
import { copyAssessment } from "src/entities/hr/assessment-create";
import { Checkbox } from "src/shared/models";
import _ from "lodash";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";
import {
  $forceTerminateAssessments,
  requestForceTerminateAssessments,
} from "../../../entities/public/force-terminate-assessments";

interface Props {}

export const PlanedPage: FC<Props> = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    items: assessments,
    pagination,
    status: assessmentsStatus,
  } = useUnit($assessmentsListAdmin);

  const { status: forceTerminateAssessmentsStatus } = useUnit(
    $forceTerminateAssessments,
  );

  const isTerminateLoading: boolean =
    forceTerminateAssessmentsStatus === ERequestStatus.LOADING;

  const createPermission = useCheckPermission(Permissions.SessionsCreate);

  const terminatePermission = useCheckPermission(Permissions.SessionsTerminate);

  const { fetchAssessments, resetAssessments } = assessmentsModel;

  const { fetchSimulations, resetSimulations } = simulationsModel;

  const [urlState, setUrlState] = useUrlState();

  const [checkList, setCheckList] = useState<string[]>([]);

  const [copiesCount, setCopiesCount] = useState<number | null>(null);

  const [autoNameCopies, setAutoNameCopies] = useState<boolean>(true);

  const [copiesNames, setCopiesNames] = useState<Record<number, string>>({});

  const isAssessmentsLoading: boolean =
    assessmentsStatus === ERequestStatus.LOADING;

  const assessmentTypes = useMemo<AssessmentType[]>(
    () => Object.values(AssessmentType),
    [],
  );

  useEffect(() => {
    fetchAssessments({ assessmentStatusQuery: [AssessmentStatus.Lobby] });

    fetchSimulations();

    return () => {
      resetAssessments();

      resetSimulations();
    };
  }, [fetchAssessments, fetchSimulations, resetAssessments, resetSimulations]);

  const requestAssessmentsParams: IRequestAssessmentsListAdmin = useMemo(() => {
    const {
      pageNum,
      pageSize,
      orderBy,
      title,
      gameTitle,
      curPlayersFrom,
      curPlayersTo,
      closedSession,
    } = urlState.query;

    let assessmentTypeValues: AssessmentType[] = urlState.query.assessmentType
      ? (urlState.query.assessmentType.split(",") as AssessmentType[])
      : [];

    assessmentTypeValues = assessmentTypeValues.filter((assessmentTypeValue) =>
      assessmentTypes.includes(assessmentTypeValue),
    );

    let gameTypeValues = (urlState.query.gameType?.split(",") ?? []) as Array<
      "single" | "solo" | "team" | "corp"
    >;

    return {
      params: {
        localeQuery: "ru",
        assessmentStatusQuery: [AssessmentStatus.Lobby],
        pageSize: pageSize ? Number(pageSize) : 50,
        pageNum: pageNum ? Number(pageNum) : 1,
        orderByQuery: orderBy,
        titleQuery: title ? [title] : undefined,
        gameTitleQuery: gameTitle ? [gameTitle] : undefined,
        assessmentTypeQuery: !!assessmentTypeValues.length
          ? assessmentTypeValues
          : undefined,
        gameTypeQuery: !!gameTypeValues.length ? gameTypeValues : undefined,
        curPlayersFromQuery: curPlayersFrom
          ? Number(curPlayersFrom)
          : undefined,
        curPlayersToQuery: curPlayersTo ? Number(curPlayersTo) : undefined,
        closedSession: closedSession ? closedSession === "true" : undefined,
      },
    };
  }, [assessmentTypes, urlState.query]);

  useEffect(() => {
    requestAssessmentsListAdmin(requestAssessmentsParams);
  }, [requestAssessmentsParams]);

  useEffect(() => {
    return () => {
      cleanAssessmentsListAdmin();
    };
  }, [dispatch]);

  const onUpdateQueryParams = useCallback(
    (newQueryParams: TDataGridDataParams) => {
      if (!isEqual(urlState.query, newQueryParams)) {
        setUrlState((prevState) => ({
          ...prevState,
          query: { ...newQueryParams, pageNum: "1" },
        }));
      }
    },
    [urlState, setUrlState],
  );

  const goToPage = (pageNum: number, isReadmoreValue: boolean = false) => {
    setAssessmentsListAdminIsReadmore({ value: isReadmoreValue });

    setUrlState((prevState) => ({
      ...prevState,
      query: { ...prevState.query, pageNum: String(pageNum) },
    }));
  };

  const exportLinks = () => {
    exportAssessments({
      assessmentIds: checkList,
    });
  };

  const cancelExport = () => {
    setCheckList([]);
  };

  const activePopups = useUnit($activePopups);

  const copiesCreate = () => {
    const assessmentId = activePopups.find(
      ({ name }) => name === EPopupName.COPY_SESSION_POPUP,
    )?.data.id;

    if (assessmentId && copiesCount) {
      copyAssessment({
        params: {
          assessmentId,
          assessmentCopyReqDtoBody: {
            num_copy: copiesCount,
            auto_naming: autoNameCopies,
            names: Object.values(copiesNames),
          },
        },
        refetchParams: requestAssessmentsParams,
      });

      setCopiesCount(null);
      setCopiesNames({});
      setAutoNameCopies(true);

      closePopup({ name: EPopupName.COPY_SESSION_POPUP });
    }
  };

  const clearSelectedAssessments = () => {
    setCheckList((prevState) => {
      prevState.length = 0;
      return [];
    });
  };

  const terminateAssessment = () => {
    const callback = () => {
      requestAssessmentsListAdmin({
        params: {
          localeQuery: "ru",
          assessmentStatusQuery: [AssessmentStatus.Lobby],
          pageSize: 50,
        },
      });
      clearSelectedAssessments();
    };

    requestForceTerminateAssessments({
      body: { ids: checkList },
      callback,
    });
  };

  const openTerminatePopup = () => {
    openPopup({ name: EPopupName.FORCE_TERMINATE });
  };

  const disabledCreateCopies =
    !copiesCount ||
    (!autoNameCopies &&
      Object.values(copiesNames).filter((value) => !!value).length !==
        copiesCount);

  return (
    <PageWrapper
      title={t("assessmentsControl.title")}
      isLoadingPanel={isAssessmentsLoading}
      isLightBlueBackground
      isShowContentWhenIsLoading
      controlPanelTitle={t("assessmentsControl.controlPanel.label.assessments")}
      controlPanelMaxCount={pagination?.totalItems}
      controlPanelSelectedCount={checkList.length}
      titlePanelSlot={
        <div className="table__control">
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileSortButton />
          </div>
          <div className="table__control-btn table__control-btn--hide-on-not-mobile">
            <DataGridMobileFiltersButton />
          </div>
        </div>
      }
      controlPanelSlot={
        <div className="created-assessments-controls">
          {!!checkList.length && (
            <>
              <BaseButton small secondary onClick={cancelExport}>
                {t("common.cancel")}
              </BaseButton>
              <BaseButton
                small
                secondary
                disabled={!terminatePermission}
                onClick={openTerminatePopup}
              >
                {t("common.delete")}
              </BaseButton>
              <BaseButton small outline onClick={exportLinks}>
                {t("assessment.export")}
              </BaseButton>
            </>
          )}
          <BaseButton
            small
            primary
            onClick={openAddAssessmentPopup}
            disabled={!createPermission}
          >
            {t("usersControl.controlPanel.btn.newGame")}
          </BaseButton>
        </div>
      }
    >
      <div className="created-assessments-page">
        <div className="created-assessments-data-grid-wrapper">
          <PlanedAssessmentsTable
            assessmentItems={assessments}
            queryParams={urlState.query}
            loading={isAssessmentsLoading}
            onUpdateSortAndFilters={onUpdateQueryParams}
            pagination={pagination}
            goToPage={(pageNum) => goToPage(pageNum)}
            readmore={(pageNum) => goToPage(pageNum, true)}
            selectedRowsIds={checkList}
            onSelectRows={setCheckList}
          />
        </div>
      </div>

      <ConfirmPopup
        popupName={EPopupName.FORCE_TERMINATE}
        title={t("usersControl.popup.deleteAssessments.title")}
        confirmBtnLabel={t("common.delete")}
        onConfirm={terminateAssessment}
        isConfirmBtnDisabled={isTerminateLoading}
      />

      <BasePopup
        name={EPopupName.COPY_SESSION_POPUP}
        title={t("session.popup.copy.title")}
        closeButton={false}
        className="copy-assessment-popup"
        onClose={() => {
          setCopiesCount(null);
          setAutoNameCopies(true);
          setCopiesNames({});
        }}
      >
        <div className="copy-assessment-popup__content">
          <BaseInputNumber
            value={String(copiesCount)}
            onChange={(event) => setCopiesCount(Number(event.value))}
            placeholder={t("session.popup.number.copy")}
            min={0}
            max={99}
          />

          <InputCheckbox
            onChange={(value) => {
              setCopiesNames({});
              setAutoNameCopies(value);
            }}
            value={autoNameCopies}
            label={t("session.popup.copy.auto_names")}
            type={Checkbox.CHOSEN}
          />

          {!autoNameCopies &&
            _.range(0, copiesCount ?? 0).map((index) => (
              <BaseInputText
                placeholder={t("session.popup.copy.name", { index: index + 1 })}
                key={index}
                value={copiesNames[index]}
                onChange={(event) =>
                  setCopiesNames((prev) => ({ ...prev, [index]: event.value }))
                }
                maxLength={30}
              />
            ))}

          <BaseButton
            primary
            onClick={copiesCreate}
            disabled={disabledCreateCopies}
          >
            {t("session.popup.copy.create")}
          </BaseButton>
        </div>
      </BasePopup>
    </PageWrapper>
  );
};
