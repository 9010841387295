import { ERequestStatus } from "../../types";
import { TContactsActions } from "./actionCreators";
import { EContactsActions } from "./actionTypes";
import { IContactsState } from "./type";

const initialState: IContactsState = {
  items: [],
  item: null,
  statusItemsGet: ERequestStatus.NEVER,
  statusItemGet: ERequestStatus.NEVER,
  statusItemUpdate: ERequestStatus.NEVER,
  statusItemDelete: ERequestStatus.NEVER,
  statusItemCreate: ERequestStatus.NEVER,
};

export const contactsReducer = (
  state = initialState,
  action: TContactsActions,
) => {
  switch (action.type) {
    case EContactsActions.CLEAN_CONTACTS:
      return {
        ...state,
        items: [],
        statusItemsGet: ERequestStatus.NEVER,
        statusItemDelete: ERequestStatus.NEVER,
      };
    case EContactsActions.REQUEST_CONTACTS_GET:
      return {
        ...state,
        statusItemsGet: ERequestStatus.LOADING,
      };
    case EContactsActions.SUCCESS_CONTACTS_GET:
      return {
        ...state,
        items: action.payload,
        statusItemsGet: ERequestStatus.LOADED,
      };
    case EContactsActions.FAILURE_CONTACTS_GET:
      return {
        ...state,
        statusItemsGet: ERequestStatus.ERROR,
      };
    case EContactsActions.CLEAN_CONTACT:
      return {
        ...state,
        item: null,
        statusItemGet: ERequestStatus.NEVER,
        statusItemUpdate: ERequestStatus.NEVER,
        statusItemCreate: ERequestStatus.NEVER,
      };
    case EContactsActions.REQUEST_CONTACT_GET:
      return {
        ...state,
        statusItemGet: ERequestStatus.LOADING,
      };
    case EContactsActions.SUCCESS_CONTACT_GET:
      return {
        ...state,
        item: action.payload,
        statusItemGet: ERequestStatus.LOADED,
      };
    case EContactsActions.FAILURE_CONTACT_GET:
      return {
        ...state,
        statusItemGet: ERequestStatus.ERROR,
      };
    case EContactsActions.REQUEST_CONTACT_UPDATE:
      return {
        ...state,
        statusItemUpdate: ERequestStatus.LOADING,
      };
    case EContactsActions.SUCCESS_CONTACT_UPDATE:
      return {
        ...state,
        statusItemUpdate: ERequestStatus.LOADED,
      };
    case EContactsActions.FAILURE_CONTACT_UPDATE:
      return {
        ...state,
        statusItemUpdate: ERequestStatus.ERROR,
      };
    case EContactsActions.REQUEST_CONTACT_DELETE:
      return {
        ...state,
        statusItemDelete: ERequestStatus.LOADING,
      };
    case EContactsActions.SUCCESS_CONTACT_DELETE:
      return {
        ...state,
        statusItemDelete: ERequestStatus.LOADED,
      };
    case EContactsActions.FAILURE_CONTACT_DELETE:
      return {
        ...state,
        statusItemDelete: ERequestStatus.ERROR,
      };
    case EContactsActions.REQUEST_CONTACT_CREATE:
      return {
        ...state,
        statusItemCreate: ERequestStatus.LOADING,
      };
    case EContactsActions.SUCCESS_CONTACT_CREATE:
      return {
        ...state,
        statusItemCreate: ERequestStatus.LOADED,
      };
    case EContactsActions.FAILURE_CONTACT_CREATE:
      return {
        ...state,
        statusItemCreate: ERequestStatus.ERROR,
      };
    default:
      return state;
  }
};
