import { FC, memo, ReactElement } from "react";

interface Props {
  className?: string;
}

export const MagnifierIcon: FC<Props> = memo(
  ({ className }): ReactElement => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.6 7.20092C1.6 4.1087 4.10674 1.60195 7.19897 1.60195C10.2912 1.60195 12.7979 4.1087 12.7979 7.20092C12.7979 8.70527 12.2046 10.071 11.2393 11.077C11.2091 11.0997 11.1801 11.1247 11.1527 11.1522C11.1251 11.1797 11.1 11.2088 11.0773 11.2391C10.0711 12.2057 8.7044 12.7999 7.19897 12.7999C4.10674 12.7999 1.6 10.2931 1.6 7.20092ZM11.6937 12.8246C10.462 13.8104 8.89932 14.3999 7.19897 14.3999C3.22309 14.3999 0 11.1768 0 7.20092C0 3.22504 3.22309 0.00195312 7.19897 0.00195312C11.1748 0.00195312 14.3979 3.22504 14.3979 7.20092C14.3979 8.90002 13.8093 10.4616 12.8248 11.693L15.7635 14.6316C16.0759 14.944 16.0759 15.4506 15.7635 15.763C15.4511 16.0754 14.9445 16.0754 14.6321 15.763L11.6937 12.8246Z"
        fill="currentColor"
      />
    </svg>
  ),
);
