import { RootState } from "../../store";
import { ERequestStatus } from "../../types";
import { IUsersControlState } from "./type";

export const selectUsersControl = (state: RootState): IUsersControlState =>
  state.usersControl;

export const selectUsersControlDeleteStatus = (
  state: RootState,
): ERequestStatus => selectUsersControl(state).statusDelete;

export const selectUsersControlDisableStatus = (
  state: RootState,
): ERequestStatus => selectUsersControl(state).statusDisable;

export const selectUsersControlEnableStatus = (
  state: RootState,
): ERequestStatus => selectUsersControl(state).statusEnable;
