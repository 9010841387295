import { combine, sample } from "effector";

import { IBaseCardListItem } from "src/shared/components";

import { $currentLanguage } from "src/shared/helpers";

import { $simulations } from "src/entities/admin/simulations/model";

import { getBaseCardListItemsAdapter } from "../lib/getBaseCardListItemsAdapter";

import { simulationsAdminFeaturesDomain } from "./domain";
import { $myPermissions } from "../../../../entities/public/permissions/model";

// Stores

export const $cardListItems = simulationsAdminFeaturesDomain.createStore<
  IBaseCardListItem[]
>([]);

// Logic

sample({
  clock: combine(
    $simulations,
    $currentLanguage,
    $myPermissions,
    getBaseCardListItemsAdapter,
  ),
  target: $cardListItems,
});
