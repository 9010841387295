import { Manager } from "../../../../generated/social";
import { RootState } from "../../store";
import { ERequestStatus } from "../../types";
import { IContactsState } from "./type";

export const selectContacts = (state: RootState): IContactsState =>
  state.contacts;

export const selectContactsItems = (state: RootState): Manager[] =>
  selectContacts(state).items;

export const selectContactsItem = (state: RootState): Manager | null =>
  selectContacts(state).item;

export const selectContactsItemsStatusGet = (
  state: RootState,
): ERequestStatus => selectContacts(state).statusItemsGet;

export const selectContactsItemStatusGet = (state: RootState): ERequestStatus =>
  selectContacts(state).statusItemGet;

export const selectContactsItemStatusUpdate = (
  state: RootState,
): ERequestStatus => selectContacts(state).statusItemUpdate;

export const selectContactsItemStatusDelete = (
  state: RootState,
): ERequestStatus => selectContacts(state).statusItemDelete;

export const selectContactsItemStatusCreate = (
  state: RootState,
): ERequestStatus => selectContacts(state).statusItemCreate;
