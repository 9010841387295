import React, { FC, useMemo, useState } from "react";
import { ELanguage } from "../../../../../shared/store/types";
import BaseTextEditor from "../../../../../shared/components/form-fields/base-text-editor/base-text-editor";
import _ from "lodash";
import { BaseBox } from "../../../../../shared/components";
import { useTranslation } from "react-i18next";
import { useQuery } from "../../../../../shared/hooks/useQuery";
import { LocStrDto } from "../../../../../generated/game";
import { IGameRules } from "../../lib/types";

interface Props {
  gameRules: IGameRules;
  setGameRules: (payload: IGameRules) => void;
  setRef: (payload: HTMLElement) => void;
  simulationDescription: LocStrDto;
  setSimulationDescription: (payload: LocStrDto) => void;
}

export const RulesBlock: FC<Props> = ({
  gameRules,
  setGameRules,
  setRef,
  simulationDescription,
  setSimulationDescription,
}) => {
  const { t } = useTranslation();

  const { getQuery } = useQuery();

  const selectedGameTypeQuery: string | null = getQuery("gameType");

  const [textEditorLanguage, setTextEditorLanguage] = useState<ELanguage>(
    ELanguage.RU,
  );

  const textEditorValue = useMemo(() => {
    let value;
    if (selectedGameTypeQuery) {
      if (selectedGameTypeQuery in gameRules) {
        value = gameRules?.[selectedGameTypeQuery][textEditorLanguage];
      } else {
        setGameRules({
          ...gameRules,
          [selectedGameTypeQuery]: {
            en: "",
            ru: "",
          },
        });
      }
    } else {
      value = simulationDescription[textEditorLanguage];
    }
    return value || "";
  }, [
    gameRules,
    selectedGameTypeQuery,
    simulationDescription,
    textEditorLanguage,
  ]);

  const changeHandler = (value: string, source?: string) => {
    if (source !== "user") {
      return;
    }

    const formattedObject = (
      originalObject: Record<string, string>,
      key: string,
    ): {} => {
      const defaultTextEditorValue = "<p><br></p>";

      const objectClone = _.clone(originalObject);
      if (value === defaultTextEditorValue) {
        delete objectClone[key];
        return objectClone;
      }
      objectClone[key] = value;
      return objectClone;
    };

    if (selectedGameTypeQuery && gameRules) {
      const obj = formattedObject(
        gameRules[selectedGameTypeQuery] as Record<string, string>,
        textEditorLanguage,
      );
      setGameRules({
        ...gameRules,
        [selectedGameTypeQuery]: obj,
      });
    } else if (gameRules) {
      setSimulationDescription(
        formattedObject(
          simulationDescription as Record<string, string>,
          textEditorLanguage,
        ),
      );
    }
  };

  return (
    <BaseBox className="simulation-page__rules-block">
      <div className="simulation-page__rules-block-title">
        {t("simulationControl.simulationPage.rulesBlock.title")}{" "}
        <button
          className="simulation-page__rules_language-btn"
          onClick={() => {
            if (textEditorLanguage === ELanguage.RU) {
              setTextEditorLanguage(ELanguage.EN);
            } else {
              setTextEditorLanguage(ELanguage.RU);
            }
          }}
        >
          {textEditorLanguage.toUpperCase()}
        </button>
      </div>
      <BaseTextEditor
        setRef={setRef}
        name="rulesContent"
        value={textEditorValue}
        onChange={({ value, source }) => changeHandler(value, source)}
        className="simulation-page__rules-text-input"
      />
    </BaseBox>
  );
};
