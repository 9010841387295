import { ERequestStatus } from "../../types";

import { SimulationItemAdminActions } from "./actionCreators";

import { SimulationItemAdminActionsType } from "./actionTypes";

import { SimulationItemAdminStateInterface } from "./type";

const initialState: SimulationItemAdminStateInterface = {
  item: null,
  calcs: [],
  testFormula: [],
  statusGetSimulation: ERequestStatus.NEVER,
  statusGetCalcs: ERequestStatus.NEVER,
  statusUpdateSimulation: ERequestStatus.NEVER,
  statusPullMeta: ERequestStatus.NEVER,
  statusTestFormula: ERequestStatus.NEVER,
};

export const simulationItemAdminReducer = (
  state = initialState,
  action: SimulationItemAdminActions,
) => {
  switch (action.type) {
    case SimulationItemAdminActionsType.CLEAN_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        item: null,
        calcs: [],
        testFormula: [],
        statusGetSimulation: ERequestStatus.NEVER,
        statusGetCalcs: ERequestStatus.NEVER,
        statusUpdateSimulation: ERequestStatus.NEVER,
        statusPullMeta: ERequestStatus.NEVER,
        statusTestFormula: ERequestStatus.NEVER,
      };
    case SimulationItemAdminActionsType.REQUEST_GET_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        statusGetSimulation: ERequestStatus.LOADING,
      };
    case SimulationItemAdminActionsType.REQUEST_PUT_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        statusUpdateSimulation: ERequestStatus.LOADING,
      };
    case SimulationItemAdminActionsType.REQUEST_PULL_META_ADMIN:
      return {
        ...state,
        statusPullMeta: ERequestStatus.LOADING,
      };
    case SimulationItemAdminActionsType.SUCCESS_GET_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        item: action.payload.game,
        statusGetSimulation: ERequestStatus.LOADED,
      };
    case SimulationItemAdminActionsType.SUCCESS_PUT_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        statusUpdateSimulation: ERequestStatus.LOADED,
      };
    case SimulationItemAdminActionsType.SUCCESS_PULL_META_ADMIN:
      return {
        ...state,
        statusPullMeta: ERequestStatus.LOADED,
      };
    case SimulationItemAdminActionsType.FAILURE_GET_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        statusGetSimulation: ERequestStatus.ERROR,
      };
    case SimulationItemAdminActionsType.FAILURE_PUT_SIMULATION_ITEM_ADMIN:
      return {
        ...state,
        statusUpdateSimulation: ERequestStatus.ERROR,
      };
    case SimulationItemAdminActionsType.FAILURE_PULL_META_ADMIN:
      return {
        ...state,
        statusPullMeta: ERequestStatus.ERROR,
      };
    default:
      return state;
  }
};
