import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DataGrid } from "../..";

import {
  EDataGridColumnType,
  EDataGridFilterType,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "../../../models/dataGrid";

import { languagePicker } from "../../../helpers/languagePicker";
import { useTranslation } from "react-i18next";
import { AssessmentStatus, AssessmentType } from "../../../../generated/game";
import { AssessmentBriefDto } from "../../../../generated/statistic";
import { getActiveGroup } from "../../../helpers/data-grids/getActiveGroup";
import { getDateControlPanelGroup } from "../../../helpers/data-grids/getControlPanelGroup";
import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";

interface Props {
  assessmentItems: AssessmentBriefDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
}

const { openPopup } = popupModel;

export const FinishedAssessmentsTable: FC<Props> = ({
  loading,
  assessmentItems,
  queryParams,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
}): ReactElement => {
  const { i18n, t } = useTranslation();

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const getStatusText = useCallback(
    (status: AssessmentStatus): string | undefined => {
      if (status === AssessmentStatus.Lobby) {
        return t("table.header.status.game.lobby");
      }

      if (status === AssessmentStatus.InProgress) {
        return t("table.header.status.game.inProgress");
      }
    },
    [t],
  );

  const getOrderByValue = (
    computedValue?: string,
    values: string[] = [],
  ): string => {
    return computedValue?.split(",").find((val) => values.includes(val)) || "";
  };

  const assessmentTypes = useMemo(() => Object.values(AssessmentType), []);

  const assessmentTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return assessmentTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [assessmentTypes, t]);

  const statusOptions = useMemo<IDataGridSelectOption[]>(() => {
    return [
      {
        value: "finished",
        label: t("table.header.status.game.finished"),
      },
      {
        value: "canceled",
        label: t("table.header.status.game.canceled"),
      },
    ];
  }, []);

  const showResults = useCallback((id: string) => {
    openPopup({
      name: EPopupName.ASSESSMENT_RESULT,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: id }],
    });
  }, []);

  const openConfig = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_PARAMETERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
      someBooleanTrigger: true,
    });
  }, []);

  const generateColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("table.header.session"),
        type: EDataGridColumnType.STRING,
        key: "sessionName",
        showed: true,
        filterTitle: t("table.header.session"),
        controlPanel: {
          title: t("table.header.session"),
          activeGroupKey: getActiveGroup(
            ["title"],
            { all: ["title"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "title",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.title || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "title",
                  "-title",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "title",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-title",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.simulation"),
        type: EDataGridColumnType.STRING,
        key: "simulationName",
        showed: true,
        controlPanel: {
          title: t("table.header.simulation"),
          activeGroupKey: getActiveGroup(
            ["gameTitle"],
            { all: ["gameTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gameTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.gameTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "gameTitle",
                  "-gameTitle",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "gameTitle",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-gameTitle",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.type"),
        type: EDataGridColumnType.STRING,
        key: "assessmentType",
        showed: true,
        filters: [
          {
            key: "assessmentType",
            type: EDataGridFilterType.CHECKBOX,
            placeholder: "Не выбрано",
            value: queryParams?.assessmentType || "",
            items: assessmentTypeOptions,
          },
        ],
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["assessmentType"],
            { all: ["assessmentType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "assessmentType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: "Не выбрано",
                  value: queryParams?.assessmentType || "",
                  items: assessmentTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.members.short"),
        type: EDataGridColumnType.STRING,
        key: "players",
        showed: true,
        controlPanel: {
          title: t("table.header.members"),
          activeGroupKey: getActiveGroup(
            ["curPlayers", "curPlayersFrom", "curPlayersTo"],
            { all: ["curPlayers", "curPlayersFrom", "curPlayersTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "curPlayersFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.curPlayersFrom || "",
                  isHalf: true,
                },
                {
                  key: "curPlayersTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.curPlayersTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "curPlayers",
                  "-curPlayers",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "curPlayers",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-curPlayers",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.game.end"),
        type: EDataGridColumnType.DATETIME,
        key: "finished",
        showed: true,
        controlPanel: {
          title: t("table.header.game.end"),
          activeGroupKey: getActiveGroup(
            [
              "created",
              "createdFrom",
              "createdTo",
              "started",
              "startedFrom",
              "startedTo",
              "finished",
              "finishedFrom",
              "finishedTo",
            ],
            {
              created: ["created", "createdFrom", "createdTo"],
              started: ["started", "startedFrom", "startedTo"],
              finished: ["finished", "finishedFrom", "finishedTo"],
            },
            queryParams,
            "orderBy",
            true,
          ),
          groups: [
            getDateControlPanelGroup(
              "created",
              t("table.header.creationDate"),
              queryParams,
            ),
            getDateControlPanelGroup(
              "started",
              t("table.header.dateRun"),
              queryParams,
            ),
            getDateControlPanelGroup(
              "finished",
              t("table.filters.game.finishedAt"),
              queryParams,
            ),
          ],
        },
      },
      {
        title: t("table.header.status"),
        type: EDataGridColumnType.STRING,
        key: "status",
        showed: true,
        filters: [
          {
            key: "status",
            type: EDataGridFilterType.CHECKBOX,
            placeholder: "Не выбрано",
            value: queryParams?.status || "",
            items: statusOptions,
          },
        ],
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["status"],
            { all: ["status"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "status",
                  type: EDataGridFilterType.CHECKBOX,
                  onlyOneActiveOption: true,
                  placeholder: "Не выбрано",
                  value: queryParams?.status || "",
                  items: statusOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.findMember"),
        type: EDataGridColumnType.BUTTON,
        key: "resultsButtonTitle",
        showed: true,
        controlPanel: {
          title: t("table.header.findMember"),
          activeGroupKey: getActiveGroup(
            ["firstName", "lastName"],
            { all: ["firstName", "lastName"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "firstName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.name"),
                  value: queryParams?.firstName || "",
                },
                {
                  key: "lastName",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("table.header.lastName"),
                  value: queryParams?.lastName || "",
                },
              ],
            },
          ],
        },
        noPaddingRight: true,
        buttonProps: {
          primary: true,
          xSmall: true,
          xSmallRounded: true,
          disabled: false,
          onClick(rowId: string) {
            showResults(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 59,
          },
          laptop: {
            right: 79,
          },
        },
      },
      {
        title: t("table.header.settings"),
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "settingsButtonTitle",
        showed: true,
        buttonProps: {
          small: true,
          hideLabel: true,
          transparent: true,
          icon: "props-gray",
          noPaddingX: true,
          ariaLabel: t("buttons.sessionSettings"),
          onClick(rowId) {
            openConfig(rowId);
          },
        },
        sticky: {
          tablet: {
            right: 0,
            width: 59,
          },
          laptop: {
            right: 0,
          },
        },
      },
    ],
    [assessmentTypeOptions, queryParams, openConfig, showResults, t],
  );

  useEffect(() => {
    const rowsItems: IDataGridRow[] = assessmentItems.map(
      (item): IDataGridRow => ({
        id: item.aId,
        selected: false,
        sessionName: item.aTitle,
        simulationName: languagePicker(item.gTitle, i18n.language),
        assessmentType: t(`common.assessment.${item.aType}`, item.aType),
        players: `${item.curPlayers}/${item.maxPlayers}`,
        finished: item.finishedAt,
        resultsButtonTitle: t("table.header.results"),
        settingsButtonTitle: t("table.header.settings"),
        status: t(`table.header.status.game.${item.status}`),
        tooltips: {
          finished: {
            items: [
              {
                key: t("table.header.game.created"),
                value: item.createdAt,
                type: "datetime",
              },
              {
                key: t("table.header.game.run"),
                value: item.startedAt,
                type: "datetime",
              },
              {
                key: t("table.header.game.end"),
                value: item.finishedAt,
                type: "datetime",
              },
            ],
          },
        },
      }),
    );

    setRows(rowsItems);
  }, [assessmentItems, i18n.language, t, getStatusText]);

  useEffect(() => {
    setColumns(generateColumns);
  }, [generateColumns]);

  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"active-assessment-table"}
      queryParams={queryParams}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      goToPage={goToPage}
      readmore={readmore}
    />
  );
};
