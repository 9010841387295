import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useUnit } from "effector-react";

import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

import isReachable from "is-reachable";

import classNames from "classnames";

import {
  AssessmentDto,
  AssessmentStatus as AssessmentStatusGame,
  AssessmentType,
  CreatedBy,
  GameType,
  LocStrDto,
} from "src/generated/game";

import {
  AssessmentStatus,
  Join as UserInfoDto,
  Join,
  Quit,
} from "src/generated/ws4";

import useClient from "src/shared/hooks/useClient";

import { languagePicker } from "src/shared/helpers";

import { BaseLoader, ConfirmPopup, SelectItem } from "src/shared/components";

import { IGameType, ISimulationPlayer } from "src/shared/store/types";

import {
  roomClear,
  roomError,
  selectRoomName,
  setAssessmentStatus,
} from "src/shared/store/ducks/room";

import {
  cleanSimulationListPlayer,
  requestSimulationListPlayer,
  $simulationListPlayer,
} from "src/entities/public/simulation-list-player";

import { requestForceTerminateAssessments } from "src/entities/public/force-terminate-assessments";

import { ITeam } from "./components/session-table/session-table";

import {
  ConfirmPopupLoadingHoc,
  ConfirmPresencePopup,
  SelectingDesiredRolePage,
  SessionHeader,
  SessionTable,
} from "./components";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import {
  leaveSelectedPlayer,
  receiveSelectedPlayer,
  removeAssessmentMembers,
  setSelectedAssessment,
} from "src/features/hr/assessmnet-invite/model";

import { SessionRulesPopup } from "src/features/public/session-rules-popup/session-rules-popup";

import {
  $socketGameV4RoomHrCommander,
  $socketGameV4RoomHrHandler,
  $socketGameV4RoomPlayerCommander,
  $socketGameV4RoomPlayerHandler,
} from "src/shared/api/public/sockets/model/v4";

import "./session-page.scss";
import _ from "lodash";
import { closePopup } from "../../../shared/components/base-popup/model";
import {
  getAssessmentByCode,
  getAssessmentRequest,
} from "../../../shared/api/hr/assessments";
import {
  $sessionTeams,
  setSessionTeams,
} from "src/entities/hr/assessment-players/model/assessment-players";
import { $keycloak } from "../../../entities/public/keycloak/model";

const { openPopup, closeAllPopup } = popupModel;

interface UnallocatePopupVariant {
  title: string;
  description: string;
}

export const SessionPage: FC = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const storeKeycloak = useUnit($keycloak);

  const { id, code } = useParams<{
    id?: string;
    code?: string;
  }>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const socketPlayerRoom = useUnit($socketGameV4RoomPlayerHandler);

  const socketHrRoom = useUnit($socketGameV4RoomHrHandler);

  const socketPlayerRoomCommander = useUnit($socketGameV4RoomPlayerCommander);

  const socketHrRoomCommander = useUnit($socketGameV4RoomHrCommander);

  const { items: simulationsList } = useUnit($simulationListPlayer);

  const room = useSelector(selectRoomName);

  const { isPlayerClientId, isHrClientId } = useClient();

  const simulation: ISimulationPlayer | undefined = useMemo(
    () => simulationsList?.find((item) => item.id === room.gameId),
    [simulationsList, room.gameId],
  );

  const gameType: IGameType | undefined = useMemo(
    () =>
      room.gameType
        ? simulation?.rawMetaJson.gameTypes[room.gameType]
        : undefined,
    [simulation, room.gameType],
  );

  const nonLobbyStatus = room.assessmentStatus !== AssessmentStatus.LOBBY;

  const [simulationTitle, setSimulationTitle] = useState<string>("");

  const [roomLogo, setRoomLogo] = useState<string>("");

  const [userToDelete, setUserToDelete] = useState<string>();

  const [usersToUnAllocate, setUsersToUnAllocate] = useState<string[]>([]);

  const [online, setOnline] = useState<boolean>(window.navigator.onLine);

  const [assessmentInfo, setAssessmentInfo] = useState<AssessmentDto>();

  const [unallocatePopupVariant, setUnallocatePopupVariant] =
    useState<UnallocatePopupVariant | null>(null);

  const unallocatePopupVariants: {
    [key: string]: UnallocatePopupVariant;
  } = {
    player: {
      title: t("popup.confirm.unAllocatePlayer.title"),
      description: t("popup.confirm.unAllocatePlayer.description"),
    },
    team: {
      title: t("popup.confirm.unAllocateTeam.title"),
      description: t("popup.confirm.unAllocateTeam.description"),
    },
    area: {
      title: t("popup.confirm.unAllocateArea.title"),
      description: t("popup.confirm.unAllocateArea.description"),
    },
  };

  const SoloGameType: boolean = room.gameType === GameType.Solo;

  const TeamGameType: boolean = room.gameType === GameType.Team;

  const CorpGameType: boolean = room.gameType === GameType.Corp;

  const userId: string | undefined = storeKeycloak?.subject;

  const isAutoLobbyOrSolo: boolean =
    !!simulation && (room.createdBy === CreatedBy.Auto || SoloGameType);

  const isRatingPlayer: boolean =
    isPlayerClientId && room.assessmentType === AssessmentType.Rating;

  const isAssessmentInLobby: boolean =
    room.assessmentStatus === AssessmentStatus.LOBBY;

  const teams = useUnit($sessionTeams);

  const onRoomConnectPlayer = useCallback((data: Join) => {
    receiveSelectedPlayer(data.pid);
  }, []);

  const onRoomLeavePlayer = useCallback((data: Quit) => {
    leaveSelectedPlayer(data.pid);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinishTimerDelay = useCallback(() => {
    if (isHrClientId || nonLobbyStatus) {
      return;
    }

    openPopup({ name: EPopupName.CONFIRM_PRESENCE });
  }, [isHrClientId, nonLobbyStatus]);

  const leaveRoom = useCallback((): void => {
    if (room.assessmentStatus !== AssessmentStatus.IN_PROGRESS) {
      dispatch(setAssessmentStatus(undefined));
    }

    dispatch(roomClear());

    if (isPlayerClientId) {
      navigate(`/my-competence`);
    }
    if (isHrClientId) {
      navigate("/sessions/planned");
    }
  }, [
    navigate,
    dispatch,
    room.assessmentStatus,
    isPlayerClientId,
    isHrClientId,
  ]);

  const onFinishedTimer = useCallback(() => {
    if (isPlayerClientId) {
      closeAllPopup();

      leaveRoom();
    }
  }, [isPlayerClientId, leaveRoom]);

  const connectToSession = useCallback((): void => {
    if (!assessmentInfo?.id) {
      return;
    }

    if (isPlayerClientId) {
      socketPlayerRoomCommander?.join({ aid: assessmentInfo.id });
    } else if (isHrClientId) {
      socketHrRoomCommander?.join({ aid: assessmentInfo.id });
    }
  }, [
    assessmentInfo?.id,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoomCommander,
    socketHrRoomCommander,
  ]);

  const startSession = (): void => {
    if (assessmentInfo?.id) {
      socketHrRoomCommander?.start({
        aid: assessmentInfo?.id,
      });
    }
  };

  const closeSession = (): void => {
    if (assessmentInfo?.id) {
      requestForceTerminateAssessments({
        body: { ids: [assessmentInfo?.id] },
        callback: leaveRoom,
      });
    }
  };

  const deletePlayer = (): void => {
    if (userToDelete && assessmentInfo?.id) {
      socketHrRoomCommander?.kick({
        aid: assessmentInfo?.id,
        pid: userToDelete,
      });
    }

    closePopup({ name: EPopupName.CONFIRM_DELETE_PLAYER });
  };

  const unAllocatePlayers = () => {
    usersToUnAllocate.forEach((player) => {
      if (room.assessmentId) {
        socketHrRoomCommander?.distribute({
          aid: room.assessmentId,
          pid: player,
        });
      }
    });
    closePopup({ name: EPopupName.CONFIRM_UNALLOCATE_PLAYER });
  };

  const openConfirmDeleteUser = (user: UserInfoDto): void => {
    setUserToDelete(user.pid);

    openPopup({ name: EPopupName.CONFIRM_DELETE_PLAYER });
  };

  const openConfirmUnAllocatePlayer = (
    users: UserInfoDto[],
    popup: "player" | "area" | "team",
  ): void => {
    const ids = users.map((user) => user.pid);
    setUsersToUnAllocate(ids);
    setUnallocatePopupVariant(unallocatePopupVariants[popup]);

    openPopup({ name: EPopupName.CONFIRM_UNALLOCATE_PLAYER });
  };

  useEffect(() => {
    const newTeams: ITeam[] = [];

    if (isAutoLobbyOrSolo) {
      const roles = Object.keys(gameType?.roles ?? {}).map(
        (roleKey: string) => {
          const roleItem = gameType?.roles?.[roleKey];

          return {
            label: languagePicker(roleItem?.title, i18n.language),
            value: roleKey,
          };
        },
      );

      newTeams.push({
        roles,
        players: room.players,
      });
    }

    if ((TeamGameType || CorpGameType) && !isAutoLobbyOrSolo) {
      const teamsRoles =
        room?.maxTeamsByName &&
        Object.keys(room.maxTeamsByName).map((name) => ({
          label: languagePicker(gameType?.teams?.[name].title, i18n.language),
          value: name,
        }));

      ["nursery", "shop"].forEach((teamName) => {
        const maxTeams = room.maxTeamsByName?.[teamName].maxTeams ?? 0;

        for (let i = 1; i <= maxTeams; i++) {
          const teamId = newTeams.length + 1;

          const roles = Object.keys(
            gameType?.teams?.[teamName].roles ?? {},
          ).map((roleKey: string) => {
            const roleItem = gameType?.teams?.[teamName]?.roles?.[roleKey];

            return {
              label: languagePicker(roleItem?.title, i18n.language),
              value: roleKey,
            };
          });

          const finalTeamName: SelectItem = {
            label: languagePicker(
              gameType?.teams?.[teamName].title,
              i18n.language,
            ),
            value: teamName,
          };

          const teamIdentifier = CorpGameType ? "corpNum" : "teamNum";

          const areaNums: SelectItem[] = [];

          if (room.maxAreas) {
            _.range(1, room.maxAreas + 1).map((areaNum) => {
              return areaNums.push({
                label: `${t("common.area")} ${areaNum}`,
                value: `${areaNum}`,
              });
            });
          }

          const players = room.players.filter((player: UserInfoDto) =>
            CorpGameType
              ? Number(player.corpNum) === teamId && player.isDistributed
              : player.teamNum === teamId && player.isDistributed,
          );

          newTeams.push({
            roles,
            players,
            teamName: finalTeamName,
            teamsRoles,
            [teamIdentifier]: teamId,
            areaNums,
          });
        }
      });
    }

    setSessionTeams(newTeams);
  }, [
    t,
    room,
    gameType,
    isAutoLobbyOrSolo,
    i18n.language,
    CorpGameType,
    TeamGameType,
  ]);

  useEffect(() => {
    if (id) {
      getAssessmentRequest({ assessmentId: id })
        .then((assessment) => {
          setAssessmentInfo(assessment.assessment);
          if (
            assessment.assessment.createdBy === "user" &&
            assessment.assessment.gameType !== GameType.Solo &&
            isPlayerClientId
          ) {
            return;
          } else {
            connectToSession();
          }
        })
        .catch(() => {
          openPopup({
            message: { text: t("alert.requestError"), isError: true },
            name: EPopupName.BASE_MESSAGE_POPUP,
            data: {
              callback: () => navigate("/my-competence"),
            },
          });
        });
    }

    if (code) {
      getAssessmentByCode({ accessCode: code })
        .then((assessment) => {
          // @ts-ignore
          if (assessment.play_url) {
            // @ts-ignore
            window.location.href = assessment.play_url.replace(
              "{locale}",
              i18n.language,
            );
            return;
          }
          setAssessmentInfo(assessment.assessment);

          if (
            assessment.assessment.createdBy === "user" &&
            assessment.assessment.gameType !== GameType.Solo &&
            isPlayerClientId
          ) {
            return;
          } else {
            connectToSession();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return openPopup({
              message: { text: t("popup.session.notFound"), isError: true },
              name: EPopupName.BASE_MESSAGE_POPUP,
              data: {
                callback: () => navigate("/my-competence"),
              },
            });
          }

          const time = new Date(error.response.data.err.msg + "+00:00");

          if (error.response.status === 403 && typeof time !== "string") {
            return openPopup({
              message: {
                text: t("popup.session.attemptsExceed", {
                  time: time.toLocaleString(),
                }),
                isError: true,
              },
              name: EPopupName.BASE_MESSAGE_POPUP,
              data: {
                callback: () => navigate("/my-competence"),
              },
            });
          }

          openPopup({
            message: { text: t("alert.requestError"), isError: true },
            name: EPopupName.BASE_MESSAGE_POPUP,
            data: {
              callback: () => navigate("/my-competence"),
            },
          });
        });
    }
  }, [
    t,
    navigate,
    connectToSession,
    isPlayerClientId,
    isHrClientId,
    dispatch,
    id,
    socketHrRoomCommander,
    socketPlayerRoomCommander,
    code,
  ]);

  useEffect(() => {
    return () => {
      if (assessmentInfo?.id) {
        if (isPlayerClientId) {
          socketPlayerRoomCommander?.quit({ aid: assessmentInfo?.id });
        }

        if (isHrClientId) {
          socketHrRoomCommander?.quit({ aid: assessmentInfo?.id });
        }
      }
    };
  }, [
    assessmentInfo?.id,
    isPlayerClientId,
    isHrClientId,
    socketPlayerRoomCommander,
    socketHrRoomCommander,
  ]);

  useEffect(() => {
    const url = String(process.env.REACT_APP_PING_URL);

    const interval = Number(process.env.REACT_APP_PING_INTERVAL);

    const networkCheck: number = window.setInterval(async () => {
      setOnline(await isReachable(url));
    }, interval);

    return () => {
      clearInterval(networkCheck);
    };
  }, []);

  useEffect(() => {
    if (
      isPlayerClientId &&
      room.assessmentId &&
      room.assessmentId !== assessmentInfo?.id
    ) {
      navigate(`/sessions/session/${room.assessmentId}`);
    }
  }, [assessmentInfo?.id, isPlayerClientId, navigate, room.assessmentId]);

  useEffect(() => {
    if (room.error) {
      dispatch(roomError(true));

      leaveRoom();
    }
  }, [room.error, dispatch, leaveRoom]);

  useEffect(() => {
    dispatch(roomClear());

    requestSimulationListPlayer();

    return () => {
      cleanSimulationListPlayer();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!!simulation?.title) {
      setSimulationTitle(languagePicker(simulation?.title, i18n.language));
    }
    if (!!simulation?.rawMetaJson?.info.logoUrl) {
      setRoomLogo(
        languagePicker(simulation?.rawMetaJson?.info.logoUrl, i18n.language),
      );
    }
  }, [i18n.language, simulation]);

  useEffect(() => {
    if (assessmentInfo?.id) {
      setSelectedAssessment({
        aid: assessmentInfo?.id,
        aTitle: room.title || "",
        gTitle: simulationTitle || "",
        logoUrl: roomLogo,
        aType: room.assessmentType as AssessmentType,
        aSt: room.assessmentStatus as string as AssessmentStatusGame,
        required_participation: room.required_participation,
        isClose: room.closed_session,
      });
    }
  }, [assessmentInfo?.id, room, simulationTitle, roomLogo]);

  useEffect(() => {
    socketPlayerRoom?.onJoin(onRoomConnectPlayer);

    return () => {
      socketPlayerRoom?.onJoin(onRoomConnectPlayer)();
    };
  }, [onRoomConnectPlayer, socketPlayerRoom]);

  useEffect(() => {
    socketHrRoom?.onJoin(onRoomConnectPlayer);

    return () => {
      socketHrRoom?.onJoin(onRoomConnectPlayer)();
    };
  }, [onRoomConnectPlayer, socketHrRoom]);

  useEffect(() => {
    socketPlayerRoom?.onQuit(onRoomLeavePlayer);

    return () => {
      socketPlayerRoom?.onQuit(onRoomLeavePlayer)();
    };
  }, [onRoomLeavePlayer, socketPlayerRoom]);

  useEffect(() => {
    socketHrRoom?.onQuit(onRoomLeavePlayer);

    return () => {
      socketHrRoom?.onQuit(onRoomLeavePlayer)();
    };
  }, [onRoomLeavePlayer, socketHrRoom]);

  const rolesCount = useMemo(() => {
    const roles =
      simulation?.rawMetaJson.gameTypes[assessmentInfo?.gameType ?? ""]?.roles;

    if (!roles || Object.keys(roles).length < 2) {
      return null;
    }

    return Object.entries(roles ?? {}).map(([role, props]) => ({
      role,
      players: room.players.filter((player) => player.role === role).length,
      max: props.maxPlayers,
    }));
  }, [room.players, assessmentInfo?.gameType, simulation]);

  const isCommandLobby: boolean =
    !isRatingPlayer &&
    !!simulation &&
    isHrClientId &&
    room.createdBy === CreatedBy.User &&
    !SoloGameType;

  const rules = simulation?.gameRules?.[
    assessmentInfo?.gameType as string
  ] as LocStrDto;

  const lobbyWithDistribution =
    assessmentInfo?.createdBy === CreatedBy.User &&
    assessmentInfo?.gameType !== GameType.Solo &&
    isPlayerClientId;

  const kickUndistributedPlayers = () => {
    if (assessmentInfo?.id) {
      const unassignedPlayers = room.players.filter(
        (player) => !player.isDistributed,
      );

      unassignedPlayers.forEach((player) => {
        socketHrRoomCommander?.kick({
          aid: assessmentInfo?.id,
          pid: player.pid,
        });
      });

      removeAssessmentMembers({
        assessmentId: assessmentInfo?.id,
        members: {
          members: unassignedPlayers.map((player) => ({
            playerId: player.pid,
          })),
        },
      });

      closePopup({
        name: EPopupName.KICK_UNDISTRIBUTED_PLAYERS,
      });
    }
  };

  return (
    <div
      className={classNames("session-page", {
        "session-page-hr-created": isCommandLobby,
      })}
    >
      {lobbyWithDistribution && (
        <SelectingDesiredRolePage
          assessment={assessmentInfo}
          leaveRoom={leaveRoom}
        />
      )}

      {!simulation && !lobbyWithDistribution && <BaseLoader />}

      {isAutoLobbyOrSolo && (
        <div className="session">
          <div className="session__wrapper">
            {assessmentInfo && (
              <SessionHeader
                room={room}
                assessment={assessmentInfo}
                roomLogo={roomLogo}
                isRatingPlayer={isRatingPlayer}
                simulationTitle={simulationTitle}
                rules={rules}
                online={online}
                leaveRoom={leaveRoom}
                startSession={startSession}
                roles={rolesCount}
              />
            )}
            {!isRatingPlayer && !!simulation && (
              <SessionTable
                isTeam={TeamGameType}
                isSolo={SoloGameType}
                isCorp={CorpGameType}
                items={teams}
                currentPlayer={userId}
                deletePlayer={openConfirmDeleteUser}
                unAllocatePlayers={openConfirmUnAllocatePlayer}
                disableSelect={!isAssessmentInLobby || isPlayerClientId}
              />
            )}
          </div>
        </div>
      )}
      {isCommandLobby && (
        <SessionTable
          isTeam={TeamGameType}
          isSolo={SoloGameType}
          isCorp={CorpGameType}
          items={teams}
          accessCode={assessmentInfo?.access_code}
          currentPlayer={userId}
          deletePlayer={openConfirmDeleteUser}
          unAllocatePlayers={openConfirmUnAllocatePlayer}
          disableSelect={!isAssessmentInLobby || isPlayerClientId}
        />
      )}
      <ConfirmPopup
        popupName={EPopupName.CONFIRM_ASSESSMENT_DELETE}
        reverse
        title={t("popup.confirm.assessment.delete.title")}
        description={t("popup.confirm.assessment.delete.description")}
        confirmBtnLabel={t("sessions.session.end")}
        onConfirm={closeSession}
      />

      <ConfirmPopupLoadingHoc
        room={room}
        render={(isStartButtonDisabled) => (
          <ConfirmPopup
            popupName={EPopupName.CONFIRM_ASSESSMENT_START}
            title={t("popup.confirm.assessment.start.title")}
            description={t("popup.confirm.assessment.start.description")}
            confirmBtnLabel={t("popup.session.run")}
            onConfirm={startSession}
            isConfirmBtnDisabled={isStartButtonDisabled}
          />
        )}
      />

      <SessionRulesPopup />

      <ConfirmPresencePopup
        t={t}
        isPlayer={isPlayerClientId}
        onFinishedTimer={onFinishedTimer}
        onFinishTimerDelay={onFinishTimerDelay}
        room={room}
      />

      <ConfirmPopup
        popupName={EPopupName.CONFIRM_DELETE_PLAYER}
        reverse
        title={t("popup.confirm.delete.player")}
        confirmBtnLabel={t("common.yes")}
        onConfirm={deletePlayer}
      />

      <ConfirmPopup
        popupName={EPopupName.CONFIRM_UNALLOCATE_PLAYER}
        reverse
        title={unallocatePopupVariant?.title ?? ""}
        description={unallocatePopupVariant?.description ?? ""}
        confirmBtnLabel={t("common.remove")}
        onConfirm={unAllocatePlayers}
      />

      <ConfirmPopup
        popupName={EPopupName.FORCE_TERMINATE}
        title={t("popup.confirm.assessment.delete.title")}
        confirmBtnLabel={t("common.yes")}
        onConfirm={closeSession}
      />

      <ConfirmPopup
        popupName={EPopupName.KICK_UNDISTRIBUTED_PLAYERS}
        title={t("popup.confirm.kickUndistributed.title")}
        description={t("popup.confirm.kickUndistributed.description")}
        confirmBtnLabel={t("common.delete")}
        onConfirm={kickUndistributedPlayers}
        reverse
      />
    </div>
  );
};
