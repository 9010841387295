import {
  Pagination,
  PAssessmentBriefDto,
  PRoleDto,
} from "../../../../generated/statistic";

import { RootState } from "../../store";

import { ERequestStatus } from "../../types";

import { ISessionsHistoryState } from "./type";

export const selectSessionHistory = (state: RootState): ISessionsHistoryState =>
  state.sessionsHistory;

export const selectSessionHistoryItems = (
  state: RootState,
): PAssessmentBriefDto[] => selectSessionHistory(state).items;

export const selectSessionHistoryItemsPagination = (
  state: RootState,
): Pagination | null => selectSessionHistory(state).pagination;

export const selectSessionHistoryItemsStatus = (
  state: RootState,
): ERequestStatus => selectSessionHistory(state).status;

export const selectSessionHistoryRoles = (state: RootState): PRoleDto[] =>
  selectSessionHistory(state).roles;

export const selectSessionHistoryRolesStatus = (
  state: RootState,
): ERequestStatus => selectSessionHistory(state).rolesStatus;

export const selectSessionHistoryIsReadmore = (state: RootState): boolean =>
  selectSessionHistory(state).isReadmore;
