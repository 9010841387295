import { useUnit } from "effector-react";
import { useEffect } from "react";
import { $myPermissions } from "src/entities/public/permissions/model";
import { PermissionDto } from "src/generated/social";

export enum Permissions {
  Sessions = "session-manager",
  SessionsCreate = "session-manager-create",
  SessionsCreateUnrated = "session-manager-create-no-rating",
  SessionsCreateRated = "session-manager-create-rating",
  SessionsCreateTemplatesOnly = "session-manager-create-no-options",
  SessionsCreateWithOptions = "session-manager-create-options",
  SessionsManageRoles = "session-manager-completion",
  SessionsInvite = "session-manager-invite",
  SessionsResults = "session-manager-result",
  SessionsResultsExport = "session-manager-result-export",
  SessionsTerminate = "session-manager-ending",
  SessionsPause = "session-manager-pause",

  Simulation = "simulation-manager",
  SimulationCreate = "simulation-manager-create",
  SimulationDelete = "simulation-manager-delete",
  SimulationOptions = "simulation-manager-options",
  SimulationTemplateOptions = "simulation-manager-template-options",

  InfoManager = "info-manager",
  InfoManagerFaq = "info-manager-faq",
  InfoManagerSidebar = "info-manager-sidebar",

  Ratings = "table-rating",
  RatingsNoExport = "table-rating-no-export-users",
  RatingsExport = "table-rating-export-users",
  RatingsUserView = "table-rating-user-view",
  RatingsPersonalInfo = "table-rating-personal-info",

  WritePermissions = "write-permissions",
  Charts = "graphics",
  Complaints = "complaints",
}

export const useCheckPermission = (permission: Permissions) => {
  const permissions = useUnit($myPermissions);

  return permissions.some(
    (item) => item.name === permission && (item.is_enabled || item.selected),
  );
};
