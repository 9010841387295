import classNames from "classnames";
import { FC, ReactElement, ReactNode } from "react";
import "./base-alert.scss";

export enum EBaseAlertType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}
interface Props {
  type?: EBaseAlertType;
  isSmall?: boolean;
  slot?: ReactElement;
  children?: ReactNode;
}

export const BaseAlert: FC<Props> = ({
  type = EBaseAlertType.WARNING,
  slot,
  children,
  isSmall,
}): ReactElement => {
  return (
    <div
      className={classNames("base-alert", `base-alert--${type}`, {
        "base-alert--small": isSmall,
      })}
    >
      <div
        className={classNames(
          "base-alert__message",
          `base-alert__message--${type}`,
          { "base-alert__message--small": isSmall },
        )}
      >
        {children}
      </div>
      {!!slot && <div className="base-alert__slot">{slot}</div>}
    </div>
  );
};
