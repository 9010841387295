export const parseOrderFromQueryString = (queryString: string = "") => {
  return queryString.split(",");
};

export const getOrderByValue = (
  computedValue?: string,
  values: string[] = [],
): string => {
  const order = parseOrderFromQueryString(computedValue).find((val) => {
    return values.includes(val);
  });

  return order || "";
};

export const isActiveOrder = (
  value: string,
  queryString: string = "",
): boolean => {
  return parseOrderFromQueryString(queryString).some((val) => val === value);
};
